import { axiosInstance } from "./axiosInstance";

export const addProgress = async (progressData) => {
  const { data } = await axiosInstance.post(
    `/api/participationprogress`,
    progressData
  );
  return data;
};

export const updateProgress = async (itemId, progressData) => {
  const { data } = await axiosInstance.patch(
    `/api/participationprogress/${itemId}`,
    progressData
  );
  return data;
};
