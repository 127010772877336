import { required, helpers, minLength } from "@vuelidate/validators";
export default function () {
  return {
    programme_referred_to: {
      required: helpers.withMessage(
        "Programme referred to cannot be empty",
        required
      ),
      minLength: minLength(2),
    },
    referring_organisation: {
      required: helpers.withMessage(
        "Referring Organisation cannot be empty",
        required
      ),
      minLength: minLength(2),
    },
    referring_healthcare_professional: {
      required: helpers.withMessage(
        "Referring Healthcare Professional cannot be empty",
        required
      ),
      minLength: minLength(2),
    },
    referring_healthcare_professional_role: {
      required: helpers.withMessage(
        "Referring Healthcare Professional Role cannot be empty",
        required
      ),
      minLength: minLength(2),
    },
    checked_contraindications_to_exercise: {
      required: helpers.withMessage(
        "Contraindications To Exercise cannot be empty",
        required
      ),
    },
    smoker: {
      required: helpers.withMessage("Selection cannot be empty", required),
    },
    diabetes: {
      required: helpers.withMessage("Selection cannot be empty", required),
    },
    registered_deaf: {
      required: helpers.withMessage("Selection cannot be empty", required),
    },
  };
}
