<template>
  <div>
    <modal :show="showFlag">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Message user</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="messageParticipantError"
          type="danger"
        >
          <strong>Warning! </strong>{{ messageParticipantError }}
        </div>

        <div>
          <div class="form-group">
            <label class="form-control-label">Message title (header)</label>
            <input
              type="text"
              class="form-control"
              placeholder="Message title (header)"
              v-model="model.title"
            />

            <div
              class="mt-2 mb--3"
              v-for="error of v$.title.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">Body</label>
            <textarea
              class="form-control"
              rows="3"
              style="resize: none"
              v-model="model.body"
              placeholder="Enter message content here"
            ></textarea>
            <div
              class="mt-2 mb--3"
              v-for="error of v$.body.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="messageUser" class="btn btn-primary my-4">
          Send message
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";
import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { sendMessage } from "../../api/participants";
export default {
  name: "message_participant",
  props: {
    showFlag: { type: Boolean, default: false },
    uuid: { type: String, default: "" },
  },
  setup(props, { emit }) {
    const messageParticipantError = ref(null);
    const model = ref({
      title: "",
      body: "",
      uuid: "",
    });
    const clearModal = () => {
      model.value.title = "";
      model.value.body = "";
      model.value.uuid = "";
    };
    const rules = {
      title: {
        required: helpers.withMessage(
          "Message title cannot be empty",
          required
        ),
        minLength: minLength(2),
      },
      body: {
        minLength: minLength(2),
        required: helpers.withMessage("Message body cannot be empty", required),
      },
    };
    const v$ = useVuelidate(rules, model.value);

    const closeModal = () => {
      emit("closeModal", true);
      dispatchAppEvent("showModal", "");
      clearModal();
    };
    const messageUser = () => {
      messageParticipantError.value = null;
      v$.value.$validate().then((valid) => {
        if (valid) {
          model.value.uuid = props.uuid
          sendMessage(model.value)
            .then(() => {
              window.location.reload(true);
            })
            .catch((err) => {
              messageParticipantError.value = err;
            });
        }
      });
    };

    watch(
      () => props.uuid,
      (newValue) => {
        model.value.uuid = newValue;
      }
    );

    return { v$, messageUser, closeModal, model, messageParticipantError };
  },
};
</script>
<style>
.middle {
  text-align: center;
}
.android {
  margin-right: 1em;
}
</style>
