import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import mitt from "mitt";

const appInstance = createApp(App);

const eventBus = mitt();
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.config.globalProperties.eventBus = eventBus;
appInstance.config.globalProperties.$isAdmin = "yes";
appInstance.mount("#app");
