import { axiosInstance } from "./axiosInstance";

export const getDevices = async () => {
  const { data } = await axiosInstance.get(`/api/authorizeddevices`);
  return data;
};

export const addDevice = async (deviceData) => {
  const { data } = await axiosInstance.post(
    `/api/authorizeddevices`,
    deviceData
  );
  return data;
};

export const updateDevice = async (deviceId, deviceData) => {
  const { data } = await axiosInstance.patch(
    `/api/authorizeddevices/id/${deviceId}`,
    deviceData
  );
  return data;
};

export const deleteDevice = async (deviceId) => {
  const { data } = await axiosInstance.delete(
    `/api/authorizeddevices/id/${deviceId}`
  );
  return data;
};
export const loadDevice = async (uuid) => {
  const { data } = await axiosInstance.get(`/api/authorizeddevices/${uuid}`);
  return data;
};

export const availableDevices = async () => {
  const { data } = await axiosInstance.get(`/api/authorizeddevices/available`);
  return data;
};
