<template>
  <base-header
    class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    style="
      min-height: 20px;
      background-image: url(img/brand/green.png);
      background-size: cover;
      background-position: center top;
    "
  >
    <span class="mask bg-gradient-success opacity-8"></span>
  </base-header>
</template>
