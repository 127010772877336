<template>
  <div>
    <modal :show="showFlag">
      <form>
        <h6 class="heading-small text-muted mb-4">Delete Participant</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="error"
          type="danger"
        >
          <strong>Warning! </strong>{{ error }}
        </div>
      </form>
      <div class="alert alert-warning" v-if="modalData">
        Are you sure you want to delete participant
        <strong
          >{{ modalData.first_name }}&nbsp;{{ modalData.last_name }} ?</strong
        >
      </div>
      <br />
      <div>
        <h3>
          This Participant and all its corresponding data deleted permanently.
          You can't undo this action.
        </h3>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="deleteParticipant" class="btn btn-danger my-4">
          Delete
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { removeParticipant } from "../../api/participants";
import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";

export default {
  name: "remove-participant-modal",
  props: {
    showFlag: { type: Boolean, default: false },
    modalData: { type: Object, default: () => {} },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const error = ref(false);

    const closeModal = () => {
      emit("closeModal", null);
      dispatchAppEvent("showModal", "");
    };
    const deleteParticipant = () => {
      error.value = null;
      removeParticipant(props.modalData.participant_id)
        .then(() => {
          dispatchAppEvent("showModal", "");
          window.location.reload(true);
        })
        .catch((err) => {
          error.value = err.response.data;
        });
    };

    return { deleteParticipant, closeModal, error };
  },
};
</script>
