<template>
  <div>
    <modal :show="displayAddSetModal">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Add Video Set</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="addSetError"
          type="danger"
        >
          <strong>Warning! </strong>{{ addSetError }}
        </div>

        <div>
          <div class="form-group">
            <label class="form-control-label">Set Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Set Name"
              v-model="model.set_name"
            />

            <div
              class="mt-2 mb--3"
              v-for="error of v$.set_name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">Number Of Weeks</label>
            <input
              type="number"
              class="form-control"
              placeholder="Number Of Weeks"
              v-model="model.number_of_weeks"
            />
            <div
              class="mt-2 mb--3"
              v-for="error of v$.number_of_weeks.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-control-label">Videos Per Week</label>
          <input
            type="number"
            class="form-control"
            placeholder="Videos Per Week"
            v-model="model.videos_in_week"
          />
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button
          v-if="!qr_code"
          @click="postVideoSet"
          class="btn btn-primary my-4"
        >
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { addVideoSet } from "../../api/videoSetApi";
export default {
  name: "add_video_set",
  props: {
    displayAddSetModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const addSetError = ref(false);
    const model = ref({
      set_name: "",
      number_of_weeks: "",
      videos_in_week: "",
    });
    const clearModal = () => {
      model.value.set_name = "";
      model.value.number_of_weeks = "";
      model.value.videos_in_week = "";
    };
    const rules = {
      set_name: {
        required: helpers.withMessage("Set Name cannot be empty", required),
        minLength: minLength(2),
      },
      number_of_weeks: {
        required: helpers.withMessage(
          "Number Of Weeks cannot be empty",
          required
        ),
      },
    };
    const v$ = useVuelidate(rules, model.value);

    const closeModal = () => {
      emit("closeModal", true);
      clearModal();
    };
    const postVideoSet = () => {
      addSetError.value = null;
      v$.value.$validate().then((valid) => {
        if (valid) {
          addVideoSet(model.value)
            .then(() => {
              closeModal();
              emit("refreshTable");
            })
            .catch((err) => {
              addSetError.value = err.response.data;
            });
        }
      });
    };

    return { v$, postVideoSet, closeModal, model, addSetError };
  },
};
</script>
<style>
.middle {
  text-align: center;
}
.android {
  margin-right: 1em;
}
</style>
