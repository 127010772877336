import { axiosInstance } from "./axiosInstance";

export const fetchParticipants = async (page) => {
  const { data } = await axiosInstance.get(
    `/api/participant?page=${page}&perPage=100`
  );
  return data;
};

export const addParticipant = async (partData) => {
  const { data } = await axiosInstance.post(`/api/participant`, partData);
  return data;
};

export const updateParticipant = async (participantId, partData) => {
  console.log(participantId, partData);
  const { data } = await axiosInstance.patch(
    `/api/participant/${participantId}`,
    partData
  );
  return data;
};

export const loadProgress = async (participantId) => {
  const { data } = await axiosInstance.get(
    `/api/participationprogress/participant/${participantId}`
  );
  return data;
};

export const sendMessage = async (messageData) => {
  const { data } = await axiosInstance.post(
    `/api/pushNotifications`,
    messageData
  );
  return data;
};

export const progressSummary = async (videoSetId, participantId) => {
  const { data } = await axiosInstance.get(
    `/api/participationprogress/summary/${videoSetId}/participant/${participantId}`
  );
  return data;
};

export const removeParticipant = async (participantId) => {
  const { data } = await axiosInstance.delete(
    `/api/participant/${participantId}`
  );
  return data;
};

export const progressParticipant = async (participantId) => {
  const { data } = await axiosInstance.patch(
    `/api/participant/${participantId}/progress`
  );
  return data;
};