import axios from "axios";

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export const signIn = async (body) => {
  const { data } = await axios.post(`${baseUrl}/login`, body, {
    withCredentials: true,
  });
  return data;
};
