<template>
  <div>
    <modal :show="userToEdit">
      <form>
        <h6 class="heading-small text-muted mb-4">Edit Admin User</h6>
        <!-- <div
          class="alert alert-warning"
          role="alert"
          v-if="loginError"
          type="danger"
        >
          <strong>Warning! </strong>{{ loginError }}
        </div> -->
        <div class="">
          <div class="">
            <RoleSelect
              @setRole="setRole"
              :selected="
                userToEdit && userToEdit.role ? userToEdit.role : 'basic access'
              "
            />
            <div class="form-group">
              <label class="form-control-label"
                >Username or Email address</label
              >

              <input
                type="text"
                class="form-control"
                placeholder="Username"
                v-model="model.user_name"
                autocomplete="off"
                disabled
              />
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">First Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="First Name"
              v-model="model.first_name"
            />
          </div>
          <div class="form-group">
            <label class="form-control-label">Last Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              v-model="model.last_name"
            />
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="edditUser" class="btn btn-primary my-4">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { patchUser } from "../../api/adminApi";
import RoleSelect from "./RoleSelect";
export default {
  components: { RoleSelect },
  name: "eddit_user",
  props: {
    userToEdit: { type: String, default: null },
  },
  setup(props, { emit }) {
    const loginError = ref(false);
    const model = ref({
      first_name: "",
      last_name: "",
      role: "",
    });

    watch(
      () => props.userToEdit,
      () => {
        if (props.userToEdit) {
          model.value = props.userToEdit;
        }
      }
    );
    const setRole = (role) => {
      model.value.role = role;
    };
    const closeModal = () => {
      emit("closeEditAdminModal", true);
    };
    const edditUser = () => {
      loginError.value = null;
      patchUser(props.userToEdit.user_name, model.value)
        .then(() => {
          emit("closeEditAdminModal", true);
          emit("refreshTable");
        })
        .catch((err) => {
          loginError.value = err.response.data.message;
        });
    };

    return { edditUser, closeModal, model, loginError, setRole };
  },
};
</script>
