<template>
  <div>
    <modal :show="showFlag" modal-classes="modal-lg">
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            :class="activeTab === 'details' ? 'active' : ''"
            @click="setActiveTab('details')"
          >
            <i class="fas fa-info-circle"></i>&nbsp;Details
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            :class="activeTab === 'videoset' ? 'active' : ''"
            @click="setActiveTab('videoset')"
          >
            <i class="fas fa-film"></i>&nbsp;Video Set
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            :class="activeTab === 'progress' ? 'active' : ''"
            @click="setActiveTab('progress')"
            v-if="progress"
          >
            <i class="fas fa-chart-line"></i>&nbsp;Progress
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            :class="activeTab === 'advanced' ? 'active' : ''"
            @click="setActiveTab('advanced')"
            v-if="progress"
          >
            <i class="fas fa-cogs"></i>&nbsp;Advanced settings
          </a>
        </li>
      </ul>
      <div class="tab-content" id="tab-content">
        <div
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="home-tab"
          :class="activeTab === 'details' ? 'show active' : ''"
        >
          <DetailsTab :participant="modalData" />
        </div>
        <div
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="profile-tab"
          :class="activeTab === 'videoset' ? 'show active' : ''"
        >
          <VideoSetTab
            :participant="modalData"
            @closeModal="closeModal"
            :progress="progress"
            @onVideoSetSelect="onVideoSetSelect"
            @setProgress="onProgressSet"
          />
        </div>
        <div
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="profile-tab"
          :class="activeTab === 'progress' ? 'show active' : ''"
          v-if="progress"
        >
          <ProgressTab
            :participant="modalData"
            :videoSet="videoSet"
            v-model:activeTab="activeTab"
            :progress="progress"
            :key="activeTab"
            @refreshData="refreshData"
            @setProgress="onProgressSet"
          />
        </div>
        <div
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="profile-tab"
          :class="activeTab === 'advanced' ? 'show active' : ''"
          v-if="progress"
        >
          <AdvancedSettings
            :participant="modalData"
            :videoSet="videoSet"
            :progress="progress"
          />
        </div>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
  import { ref, watch } from "vue";
  import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";
  import DetailsTab from "../participantsManagement/tabs/DetailsTab";
  import VideoSetTab from "../participantsManagement/tabs/VideoSetTab";
  import ProgressTab from "../participantsManagement/tabs/ProgressTab";
  import AdvancedSettings from "../participantsManagement/tabs/AdvancedSettings";
  export default {
    name: "participant_details",
    props: {
      showFlag: { type: Boolean, default: false },
      modalData: { type: Object, default: () => {} },
      selected: { type: Object, default: () => {} },
      propsProgress: { type: Object, default: () => {} },
    },
    emits: ["closeModal", "refreshData"],
    /* eslint-disable */

    components: { DetailsTab, VideoSetTab, ProgressTab, AdvancedSettings },
    setup(props, { emit }) {
      watch(
        () => props.propsProgress,
        (newVal) => {
          onProgressSet(newVal);
        }
      );
      
      const activeTab = ref("details");
      const videoSet = ref(null);
      const progress = ref(null);

      const setActiveTab = (tab) => {
        activeTab.value = tab;
      };

      const closeModal = () => {
        dispatchAppEvent("showModal", "");
        emit("closeModal");
      };

      const onProgressSet = (progr) => {
        if (Array.isArray(progr)) {
          progress.value = progr[0];
        } else {
          progress.value = progr;
        }
      };

      const onVideoSetSelect = (vst) => {
        if (Array.isArray(vst)) {
          videoSet.value = vst[0];
        } else {
          videoSet.value = vst;
        }
      };
      watch(
        () => activeTab.value,
        (newTab) => {
          if (newTab) {
            emit('refreshData', activeTab.value)
          }
        }
      )
      return {
        closeModal,
        activeTab,
        setActiveTab,
        onVideoSetSelect,
        videoSet,
        onProgressSet,
        progress,
      };
    },
  };
</script>
