<template>
  <div class="progress-tab mt-5">
    <circle-progress
      :percent="countProgress()"
      :show-percent="countProgress() > 0"
    />
    <label>Total progress %</label>

    <table class="mb-3" v-if="summaryData">
      <tr v-if="videoSet && progress">
        <td>Current week</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ progress.current_week }}
          </span>
          of
          <span class="badge badge-success badge-pill" style="font-size: 18px">
            {{ videoSet.number_of_weeks }}
          </span>
          &nbsp;total weeks
        </td>
      </tr>
      <tr v-if="videoSet">
        <td>Current day</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ progress.current_day }}
          </span>
          of
          <span class="badge badge-success badge-pill" style="font-size: 18px">
            {{ videoSet.videos_in_week }}
          </span>
          &nbsp;total days
        </td>
      </tr>
      <tr>
        <td>Days passed from start</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.daysPassed }} </span
          >&nbsp;started: {{ startDate }}
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>Total days in programme</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.todalDaysInProgramme }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>Exercise days in programme</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.excerciseDaysInProgramme }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr v-if="summaryData">
        <td>Average videos to watch per day</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.averagePerDay }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr v-if="summaryData">
        <td>Should seen by now - if progressed without falling behind </td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.shouldSeenByNow }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td>Number of actually seen videos</td>
        <td>
          <span class="badge badge-info badge-pill" style="font-size: 18px">
            {{ summaryData.actuallySeen }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr v-show="summaryData">
        <td>Days behind</td>
        <td>
          <span
            class="badge badge-pill"
            style="font-size: 18px"
            :class="[
              participant.days_behind < 2 ? 'badge-info' : '',
              participant.days_behind >= 2 ? 'badge-danger' : '',
            ]"
          >
            {{ participant.days_behind }}
          </span>
          &nbsp;
        </td>
      </tr>
      <tr v-show="summaryData">
        <td><button
            type="button" style="width: 150px"
            class="btn btn-info btn-sm btn-block mb-2"
            @click="displaySeenVideosLog = !displaySeenVideosLog"
          >Seen videos log</button>
        </td>
        <td>
           <div v-if="displaySeenVideosLog && summaryData && summaryData.seenVideos.length > 0">
            <div class="card card-custom card" v-for="seenVideo in summaryData.seenVideos" 
              :key="seenVideo.id">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="badge badge-info">
                  Week: {{seenVideo.week_number}}, Day: {{ seenVideo.day_number}} on {{ videoDate(seenVideo )}}
                </span>
              </div>
            </div>
          </div>
          <div v-else><span class='text-muted'></span></div>
          &nbsp;
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import moment from "moment";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { ref, onMounted, watch, computed } from "vue";
import { progressSummary } from "../../../api/participants";
export default {
  components: { CircleProgress },
  emits: ["setProgress", "refreshData"],
  props: {
    participant: { type: Object, default: () => {} },
    videoSet: { type: Object, default: () => {} },
    progress: { type: Object, default: () => {} },
    activeTab: {type: String, default: "details"}
  },
  setup(props, { emit }) {
    const summaryData = ref(null);
    const circleProgres = ref(CircleProgress);
    const displaySeenVideosLog = ref(false)
    watch(
      () => props.participant,
      () => {
        loadSummary();
      }
    );

    watch(
      () => props.videoSet,
      () => {
        loadSummary();
      }
    );

    watch(
      () => props.progress,
      () => {
        loadSummary();
      }
    );

    watch(
      () => props.activeTab,
      (newT) => {
        emit('refreshData', newT)
      }  
    )

    const loadSummary = () => {
      if (
        props.participant.participant_id &&
        props.videoSet &&
        props.videoSet.fitness_video_set_id
      ) {
        progressSummary(
          props.videoSet.fitness_video_set_id,
          props.participant.participant_id
        ).then((data) => {
          summaryData.value = data;
        });
      }
    };

    const countProgress = () => {
      const percentage = parseInt(props.progress.percentage_progress);
      return percentage;
    };

    const startDate = computed(() => {
      if (props.progress.started_date) {
        return moment(props.progress.started_date).format("DD/MM/YYYY");
      } else {
        return "uknown";
      }
    });
    const videoDate = (seenVideo) => {
      return moment(seenVideo.created_at).format('DD/MM/YYYY')
    }

    onMounted(() => {
      loadSummary();
    });

    return {
      summaryData,
      countProgress,
      circleProgres,
      startDate,
      displaySeenVideosLog, 
      videoDate
    };
  },
};
</script>
<style type="text/css">
span.current-counter {
  font-size: 41px;
}
</style>
