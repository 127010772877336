import { required, helpers, minLength } from "@vuelidate/validators";
export default function () {
  return {
    title: {
      required: helpers.withMessage("Video Title cannot be empty", required),
      minLength: minLength(2),
    },
    description: {
      required: helpers.withMessage("Description cannot be empty", required),
      minLength: minLength(2),
    },
  };
}
