<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Participants',
            icon: 'text-success fas fa-user-md',
            path: '/participants',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Video Sets',
            icon: 'fas fa-film text-red',
            path: '/videoset',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Authorised devices',
            icon: 'fas fa-tablet text-orange',
            path: '/authoriseddevices',
          }"
        />
        <sidebar-item
          v-if="isAdmin"
          :link="{
            name: 'Admin Users',
            icon: 'ni ni-single-02 text-yellow',
            path: '/adminusers',
          }"
        />
        <sidebar-item
          v-if="isAdmin"
          :link="{
            name: 'Server Logs',
            icon: 'ni ni-collection text-red',
            path: '/serverlogs',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import role from "../utils/roleUtils";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    isAdmin() {
      return role.isFull();
    },
  },
};
</script>
