<template>
  <div class="player">
    <modal :show="showFlag" modal-classes="modal-lg ">
      <h2>{{ video.title }}</h2>
      <br />
      <vue-plyr :options="options">
        <video
          controls
          crossorigin
          playsinline
          :data-poster="video.video_thumbnail_url"
        >
          <source :src="video.file_location_path" type="video/mp4" />
        </video>
      </vue-plyr>
      <code
        v-if="video.description"
        style="font-size: 16px; margin-top: 100px; color: black"
      >
        {{ video.description }}
      </code>
      <template v-slot:footer>
        <button class="btn btn-danger my-4" @click="remove">
          <i class="fas fa-trash-alt"></i>&nbsp; Remove
        </button>
        <button class="btn btn-warning my-4" @click="editVideo()">
          <i class="fas fa-edit"></i>&nbsp; Edit
        </button>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
      </template>
    </modal>
    <delete-video-modal
      :video="video"
      :showFlag="showDelete"
      @closeVideoDeleteModal="closeDeleteModal"
      @closeModal="closeModal"
      @refreshTable="refreshTable"
    />
    <edit-video-modal @closeModal="closeModal" :modalData="videoToedit" />
  </div>
</template>
<script>
import EditVideoModal from "./EditVideoModal";
import { ref } from "vue";
import deleteVideoModal from "./deleteVideoModal";
export default {
  components: { deleteVideoModal, EditVideoModal },
  props: {
    showFlag: { type: Boolean, default: false },
    video: { type: Object, default: () => {} },
  },
  emits: ["closeModal", "refreshTable"],
  setup(props, { emit }) {
    const options = ref({});
    const showDelete = ref(false);
    const videoToedit = ref(false);
    const closeModal = () => {
      videoToedit.value = false;
      emit("closeModal", props.video.video_id);
      emit("refreshTable");
    };

    const remove = () => {
      showDelete.value = true;
    };
    const editVideo = () => {
      videoToedit.value = props.video;
    };
    const closeDeleteModal = () => {
      showDelete.value = false;
    };

    const refreshTable = () => {
      emit("refreshTable");
    };

    return {
      closeModal,
      options,
      showDelete,
      remove,
      closeDeleteModal,
      refreshTable,
      editVideo,
      videoToedit,
    };
  },
};
</script>
