<template>
  <div class="form-group mt-3">
    <label>Please select video set</label>
    <div class="alert alert-warning mt-3" v-if="hasProgress">
      Warning! Overriding this video set and choosing a different one will reset
      all progress and set it to day 1, week 1
    </div>
    <VueMultiselect
      v-model="videoSetId"
      :options="videoSets"
      @select="onVideoSetSelect"
      :close-on-select="true"
      :clear-on-select="false"
      placeholder="Select video set"
      label="set_name"
      track-by="fitness_video_set_id"
    />
  </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import { ref, onMounted, watch } from "vue";
import { getVideoSets } from "../../api/videoSetApi";
export default {
  components: { VueMultiselect },
  props: {
    hasProgress: { type: Boolean, defualt: false },
    preselected: { type: Number, defualt: 0 },
  },

  setup(props, { emit }) {
    const videoSetId = ref(null);
    const videoSets = ref([]);

    watch(
      () => props.preselected,
      () => {
        preselectVideoSet();
      }
    );

    onMounted(() => {
      load();
      preselectVideoSet();
    });

    const preselectVideoSet = () => {
      if (props.preselected > 0) {
        const setToSelect = videoSets.value.filter(
          (vs) => vs.fitness_video_set_id === props.preselected
        );
        if (setToSelect) {
          onVideoSetSelect(setToSelect);
        }
      } else {
        onVideoSetSelect(null);
      }
    };

    const load = () => {
      getVideoSets().then((data) => {
        data.forEach((element) => {
          videoSets.value.push(element);
        });
      });
    };

    const onVideoSetSelect = (videoSet) => {
      videoSetId.value = videoSet;
      emit("onVideoSetSelect", videoSet);
    };

    const allVideoSets = () => {
      return videoSets.value;
    };
    return {
      videoSetId,
      videoSets,
      onVideoSetSelect,
      allVideoSets,
    };
  },
};
</script>
