<template>
  <div class="progress-tab">
    <div class="alert alert-info mt-3" v-if="!hasProgress">
      This participant is not currently associated with any video set. Please
      select one from the list below.
    </div>
    <VideoSetSelect
      @onVideoSetSelect="selectedVideoSet"
      :preselected="preSelected"
      ref="select"
      :hasProgress="hasProgress"
    />
    <button
      class="btn btn-warning btn-xs"
      v-if="videoSet"
      @click="associateUser"
    >
      <i class="fas fa-save"></i>&nbsp;Save
    </button>
  </div>
</template>
<script>
import moment from "moment";
import { ref, onMounted, computed, watch } from "vue";
import { loadProgress } from "../../../api/participants";
import {
  addProgress,
  updateProgress,
} from "../../../api/participationProgress";
import VideoSetSelect from "../../../pageComponents/shared/VideoSetSelect";
import Swal from "sweetalert2";
export default {
  props: {
    participant: { type: Object, default: () => {} },
    progress: { type: Object, default: () => {} },
  },
  emits: ["closeModal", "onVideoSetSelect", "setProgress"],
  components: { VideoSetSelect },
  setup(props, { emit }) {
    const participantProgress = ref({});
    const videoSet = ref(null);
    const selectedSet = ref(VideoSetSelect);

    watch(
      () => props.progress,
      (newValue) => {
        participantProgress.value = newValue;
      }
    );

    watch(
      () => props.participant,
      (newValue) => {
        if (newValue.participant_id) {
          loadAndSetProgress();
        } else {
          participantProgress.value = null;
          emit("setProgress", null);
        }
      }
    );

    onMounted(() => {
      if (props.participant.participant_id) loadAndSetProgress();
    });

    const selectedVideoSet = (vset) => {
      emit("onVideoSetSelect", vset);
      videoSet.value = vset;
    };

    const loadAndSetProgress = () => {
      loadProgress(props.participant.participant_id).then((progress) => {
        participantProgress.value = progress[0];
        emit("setProgress", progress);
      });
    };

    const associateUser = () => {
      Swal.fire({
        title: "Are you sure?",
        text:
          "This will re-set participants progress and you will not be able to revert it.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, I am sure!",
      }).then((result) => {
        if (result.isConfirmed) {
          insertOrUpdateProgress()
            .then(() => {
              loadAndSetProgress();
              Swal.fire("Done", "User associated with video set", "success");
              emit("closeModal");
            })
            .catch((e) => {
              Swal.fire("Error", e.message, "error");
              emit("closeModal");
            });
        }
      });
    };

    const insertOrUpdateProgress = () => {
      if (!hasProgress.value) {
        return addProgress({
          participant_id: props.participant.participant_id,
          fitness_video_set_id: videoSet.value.fitness_video_set_id,
          started_date: moment().toISOString(),
          last_seen_date: moment().toISOString(),
          percentage_progress: "0",
          current_week: 1,
          current_day: 1,
          video_stopped_at: "0",
        });
      } else {
        const existingId =
          participantProgress.value.fitness_participation_progress_id;
        return updateProgress(existingId, {
          participant_id: props.participant.participant_id,
          fitness_video_set_id: videoSet.value.fitness_video_set_id,
          current_week: 1,
          current_day: 1,
          started_date: moment().toISOString(),
          last_seen_date: moment().toISOString(),
          percentage_progress: "0",
          video_stopped_at: "0",
        });
      }
    };

    const hasProgress = computed(() => {
      return (
        participantProgress.value &&
        Object.keys(participantProgress.value).length > 0
      );
    });

    const preSelected = computed(() => {
      if (participantProgress.value) {
        return participantProgress.value.fitness_video_set_id;
      } else {
        return 0;
      }
    });

    return {
      participantProgress,
      selectedVideoSet,
      videoSet,
      associateUser,
      hasProgress,
      preSelected,
      selectedSet,
    };
  },
};
</script>
