<template>
  <div>
    <modal :show="showFlag" modal-classes="modal-lg">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Edit Participant</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="editModalError"
          type="danger"
        >
          <strong>Warning! </strong>{{ editModalError }}
        </div>
        <div class="row form-row">
          <div class="col-12 col-sm-12">
            <div class="form-group mb-0">
              <div class="form-group mb-0 mt-3">
                <label>Participants' device</label>
                <br />
                <strong v-if="modalData.unique_device_id !== 'empty'"
                  >{{ modalData.name }}&nbsp;{{
                    modalData.unique_device_id
                  }}</strong
                >
                &nbsp;
                <button
                  class="btn btn-sm btn-warning"
                  @click="changeDevice"
                  v-if="modalData.unique_device_id !== 'empty'"
                >
                  <i class="fas fa-trash-alt"></i>
                  &nbsp;Change
                </button>
                <VueMultiselect
                  v-if="modalData.unique_device_id === 'empty'"
                  v-model="participantData.unique_device_id"
                  @select="setDevice"
                  @change="setDevice"
                  :options="devices"
                  :close-on-select="true"
                  :clear-on-select="false"
                  placeholder="Select device"
                  label="name"
                  track-by="uuid"
                />
                <div
                  class="alert alert-warning mt-2"
                  v-if="
                    devices.length === 0 &&
                    modalData.unique_device_id === 'empty'
                  "
                >
                  <i class="fas fa-info-circle"></i>
                  No devices available - please add one in the authorised
                  devices section or remove exissting device from user
                </div>
              </div>
              <div
                class="mt-0 mb-3"
                v-for="error of v$.unique_device_id.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>First name:</label>
              <input
                type="text"
                class="form-control"
                v-model="participantData.first_name"
              />
              <div
                class="mt-0 mb-3"
                v-for="error of v$.first_name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0">
              <div class="form-group mb-0 mt-3">
                <label>Last name:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="participantData.last_name"
                />
              </div>
              <div
                class="mt-0 mb-3"
                v-for="error of v$.last_name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Date of birth</label>
              <datepicker
                :class="'form-control'"
                inputFormat="dd/MM/yyyy"
                v-model="participantData.date_of_birth"
              />
              <div
                class="mt-0 mb-3"
                v-for="error of v$.date_of_birth.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Gender</label>
              <select class="form-control" v-model="participantData.gender">
                <option>Please select...</option>
                <option
                  value="male"
                  :selected="participantData.gender === 'male'"
                >
                  Male
                </option>
                <option
                  :selected="participantData.gender === 'female'"
                  value="female"
                >
                  Female
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Email address:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="participantData.email"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Phone number:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="participantData.phone_number"
                />
              </div>
              <div
                class="mt-0 mb-3"
                v-for="error of v$.phone_number.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Secondary Phone Number:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="participantData.phone_number_secondary"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Language</label>
                <select class="form-control" v-model="participantData.language">
                  <option
                    v-for="language in languages"
                    :key="language"
                    :value="language"
                  >
                    {{ language }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Address:</label>
                <textarea
                  class="form-control"
                  style="resize: none"
                  rows="2"
                  v-model="participantData.address"
                >
                </textarea>
              </div>
              <div
                class="mt-0 mb-3"
                v-for="error of v$.address.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button class="btn btn-primary my-4" @click="updateParticipantData">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
  import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";
  import VueMultiselect from "vue-multiselect";
  import Swal from "sweetalert2";
  import participantLanguage from "../shared/globalDirectives/participantLanguage";
  import Datepicker from "vue3-datepicker";
  import { availableDevices } from "../../api/devices";
  import { updateParticipant } from "../../api/participants";
  import useVuelidate from "@vuelidate/core";
  import useParticipantValidationRules from "../validationRules/useParticipantValidationRules";
  import { defineComponent, ref, reactive, watch, onMounted } from "vue";
  import moment from "moment";
  export default defineComponent({
    name: "edit_participant",
    components: { Datepicker, VueMultiselect },
    emits: ["refreshTable", "newParticipantData"],
    props: {
      showFlag: { type: Boolean, default: false },
      modalData: {
        type: Object,
        default: () => {},
        selected: { type: Object, default: () => {} },
      },
    },
    setup(props, { emit }) {
      watch(
        () => props.modalData,
        (newValue) => {
          if (newValue) {
            (participantData.first_name = newValue.first_name),
              (participantData.last_name = newValue.last_name);
            participantData.address = newValue.address;
            participantData.email = newValue.email;
            participantData.phone_number = newValue.phone_number;
            participantData.phone_number_secondary =
              newValue.phone_number_secondary;
            participantData.gender = newValue.gender;
            participantData.language = newValue.language;
            participantData.unique_device_id = props.modalData && props.modalData.unique_device_id ? props.modalData.unique_device_id.toLowerCase() : '';
            participantData.date_of_birth = moment(
              newValue.date_of_birth
            ).toDate();
            participantData.push_notification_id =
              newValue.push_notification_id;
          }
        }
      );
      const { languages } = participantLanguage();

      let participantData = reactive({});
      const devices = ref([]);
      const editModalError = ref(null);

      const rules = useParticipantValidationRules();
      const v$ = useVuelidate(rules, participantData);

      const closeModal = () => {
        emit("closeModal");
        v$.value.$reset();
        dispatchAppEvent("showModal", "");
      };
      const updateParticipantData = () => {
        setDevice();
        console.log(participantData);
        participantData.date_of_birth = moment(
          props.modalData.date_of_birth
        ).format("YYYY-MM-DD");
        v$.value.$validate().then((valid) => {
          if (valid) {
            updateParticipant(
              props.modalData.participant_id,
              participantData
            ).then(() => {
              window.location.reload(true);
            });
          }
        });
      };

      const loadDevices = () => {
        availableDevices().then((data) => {
          data.forEach((dev) => {
            devices.value.push({
              name: `${dev.name} - ${dev.uuid}`,
              uuid: dev.uuid,
              push_notification_id: dev.push_notification_id,
            });
          });
        });
      };

      const changeDevice = () => {
        Swal.fire({
          title: "Change device for user?",
          text: "This will remove current association",
          confirmButtonText: "Change",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            updateParticipant(props.modalData.participant_id, {
              unique_device_id: "empty",
            }).then((participant) => {
              emit("newParticipantData", participant);
              loadDevices();
            });
          }
        });
      };

      const setDevice = () => {
        if (participantData.unique_device_id === "empty") {
          participantData.unique_device_id = undefined;
          participantData.name = null;
        } else if (typeof participantData.unique_device_id === "object") {
          participantData.unique_device_id =
            participantData.unique_device_id.uuid;
          participantData.name = participantData.unique_device_id.name;
        } else if (typeof participantData.unique_device_id === "string") {
          participantData.unique_device_id = props.modalData.unique_device_id;
          participantData.name = props.modalData.name;
        }
      };

      onMounted(() => {
        loadDevices();
      });

      return {
        v$,
        editModalError,
        closeModal,
        updateParticipantData,
        devices,
        participantData,
        changeDevice,
        languages,
        setDevice,
      };
    },
  });
</script>
<style>
  .middle {
    text-align: center;
  }
  .android {
    margin-right: 1em;
  }
</style>
