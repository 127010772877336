<template>
  <div>
    <modal :show="displayAddDevice">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Add Mobile Device</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="addDeviceError"
          type="danger"
        >
          <strong>Warning! </strong>{{ addDeviceError }}
        </div>

        <div>
          <div class="form-group">
            <label class="form-control-label">Identifiable Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Device Name"
              v-model="model.name"
            />
            <vuelidate-error-field :errors="v$.name.$errors" />
          </div>

          <div class="form-group">
            <label class="form-control-label">
              UUID - Universally Unique Device ID
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Device Name"
              v-model="model.uuid"
            />
            <vuelidate-error-field :errors="v$.uuid.$errors" />
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="postDevice" class="btn btn-primary my-4">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import VuelidateErrorField from "../shared/VuelidateErrorField";
import useVuelidate from "@vuelidate/core";
import useAuthorizedDeviceValidationRules from "../validationRules/useAuthorizedDeviceValidationRules";
import { addDevice } from "../../api/devices";
export default {
  name: "add_device",
  components: { VuelidateErrorField },
  props: {
    displayAddDevice: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const addDeviceError = ref(false);
    const model = ref({
      name: "",
      uuid: "",
    });
    const clearModal = () => {
      model.value.name = "";
      model.value.uuid = "";
    };
    const v$ = useVuelidate(useAuthorizedDeviceValidationRules(), model.value);

    const closeModal = () => {
      emit("closeModal", true);
      clearModal();
    };
    const postDevice = () => {
      addDeviceError.value = null;
      v$.value.$validate().then((valid) => {
        if (valid) {
          addDevice(model.value)
            .then(() => {
              closeModal();
              emit("refreshTable");
            })
            .catch((err) => {
              addDeviceError.value = err.response.data;
            });
        }
      });
    };

    return { v$, postDevice, closeModal, model, addDeviceError };
  },
};
</script>
<style>
.middle {
  text-align: center;
}
.android {
  margin-right: 1em;
}
</style>
