<template>
  <div>
    <modal :show="videoSetEdit">
      <form>
        <h6 class="heading-small text-muted mb-4">Edit Video Set</h6>
        <div class="">
          <div class="">
            <div class="form-group">
              <label class="form-control-label">Edit Set Name</label>

              <input
                type="text"
                class="form-control"
                placeholder="New Set Name"
                v-model="model.set_name"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="edditSet" class="btn btn-primary my-4">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { patchVideoSet } from "../../api/videoSetApi";
export default {
  name: "eddit_video_set",
  props: {
    videoSetEdit: { type: String, default: null },
  },
  setup(props, { emit }) {
    const setEditError = ref(false);
    const model = ref({
      set_name: "",
    });

    watch(
      () => props.videoSetEdit,
      () => {
        if (props.videoSetEdit) {
          model.value = props.videoSetEdit;
        }
      }
    );

    const closeModal = () => {
      emit("closeEditVideoModal", true);
    };
    const edditSet = () => {
      setEditError.value = null;
      patchVideoSet(props.videoSetEdit.fitness_video_set_id, model.value)
        .then(() => {
          emit("closeEditVideoModal", true);
          emit("refreshTable");
        })
        .catch((err) => {
          setEditError.value = err.response.data.message;
        });
    };

    return { edditSet, closeModal, model, setEditError };
  },
};
</script>
