<template>
  <div>
    <modal :show="modalData.day_number" modal-classes="modal-lg">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Add Video</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="addVideoError"
          type="danger"
        >
          <strong>Warning! </strong>{{ addVideoError }}
        </div>
        <div class="row form-row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label>Video Title:</label>
              <input
                type="text"
                v-model="videoData.title"
                class="form-control"
                placeholder="Title..."
              />
              <div
                class="mt-2 mb--3"
                v-for="error of v$.title.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label>Set Name:</label>
              <input
                type="text"
                class="form-control"
                :value="modalData.set_name"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label class="hello">Description:</label>
              <textarea
                type="text"
                v-model="videoData.description"
                class="form-control"
                placeholder="Description..."
              />
              <div
                class="mt-2 mb--3"
                v-for="error of v$.description.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label>Video:</label>
            </div>
            Add Video:

            <VideoUploader
              @hasVideoUrl="setVideoUrl"
              @hasFileSize="setFileSize"
              ref="uploader"
            />
            <br />
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button
          class="btn btn-primary my-4"
          :disabled="videoData.file_location_path === ''"
          @click="saveVideo"
        >
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import useVideoValidationRules from "../validationRules/useVideoValdiationRules";
import { addVideo, extractThumbnail } from "../../api/videos";
import VideoUploader from "../shared/VideoUploader";
import useVuelidate from "@vuelidate/core";
import { defineComponent, ref, reactive } from "vue";
export default defineComponent({
  name: "add_video_modal",
  components: {
    VideoUploader,
  },
  emits: ["refreshTable"],
  props: {
    modalData: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const uploader = ref(VideoUploader);
    let videoData = reactive({
      title: "",
      description: "",
      file_location_path: "",
      file_size: "",
      video_thumbnail_url: "processing....",
      fitness_video_set_id: props.modalData.fitness_video_set_id,
      day_number: props.modalData.day_number,
      week_number: props.modalData.week_number,
      video_length: "placeholder",
    });
    const addVideoError = ref(null);
    const image = ref();
    const setVideoUrl = (url) => {
      videoData.file_location_path = url;
    };
    const setFileSize = (size) => {
      videoData.file_size = size;
    };

    const clearVideoData = () => {
      videoData.title = "";
      videoData.description = "";
      videoData.file_location_path = "";
      videoData.file_size = "";
      videoData.video_thumbnail_url = "processing....";
      videoData.fitness_video_set_id = props.modalData.fitness_video_set_id;
      videoData.day_number = props.modalData.day_number;
      videoData.week_number = props.modalData.week_number;
      videoData.video_length = "placeholder";
    };

    const v$ = useVuelidate(useVideoValidationRules(), videoData);

    const closeModal = () => {
      clearVideoData();
      emit("closeModal");
      uploader.value.resetData();
      v$.value.$reset();
    };
    const saveVideo = () => {
      v$.value.$validate().then((valid) => {
        if (valid) {
          videoData.week_number = props.modalData.week_number;
          videoData.day_number = props.modalData.day_number;
          videoData.fitness_video_set_id = props.modalData.fitness_video_set_id;

          addVideo(videoData).then((data) => {
            extractThumbnail(data.video_id, data.file_location_path);
            closeModal();
            emit("refreshTable");
          });
        }
      });
    };

    return {
      v$,
      image,
      videoData,
      addVideoError,
      setVideoUrl,
      closeModal,
      saveVideo,
      setFileSize,
      uploader,
    };
  },
});
</script>
<style>
.middle {
  text-align: center;
}
.android {
  margin-right: 1em;
}
</style>
