<template>
  <div>
    <page-header />
    <div class="container-fluid mt--4 col">
      <div class="card">
        <card-header
          title="Participants"
          buttonText="Add Participant"
          :clickFn="addParticipant"
          :fullAccess="isAdmin"
        />
        <div class="table-responsive">
          <v-grid
            :columnTypes="columnTypes"
            style="min-height: 600px"
            theme="material"
            :filter="true"
            :exporting="true"
            @afteredit="runUpdate"
            :source="rows"
            :columns="columns"
            class="grid-component"
          ></v-grid>
        </div>
      </div>
    </div>

    <ParticipantDetailsModal
      :showFlag="showDetails"
      @closeModal="closeDetails"
      @refreshData="refreshData"
      :propsProgress="progress"
      :modalData="modalData"
    />

    <PushNotificationModal
      @closeModal="closeSendModal"
      :uuid="modalData.uuid"
      :showFlag="showMessageUser"
      v-if="modalData && modalData.uuid"
    />

    <EditParticipantModal
      @closeModal="closeEditModal"
      @newParticipantData="setModalData"
      :showFlag="showEditModal"
      :modalData="modalData"
    />

    <EditParticipantReferralModal
      @closeModal="closeEditReferralModal"
      @newParticipantData="setModalData"
      :showFlag="showEditReferral"
      :modalData="modalData"
    />

    <RemoveParticipantsModal
      @closeModal="closeRemoveModal"
      :modalData="modalData"
      :showFlag="showRemoveModal"
    />
  </div>
</template>
<script>
  import moment from "moment";
  import { ref, reactive, onMounted, watch } from "vue";
  import {
    fetchParticipants,
    updateParticipant,
    loadProgress,
    progressParticipant
  } from "../api/participants";
  import { listenAppEvent } from "../../server/lib/push_notifications/appEvents";
  import VGrid, { VGridVueTemplate } from "@revolist/vue3-datagrid";
  import router from "@/router";
  import PageHeader from "../pageComponents/shared/PageHeader";
  import CardHeader from "../pageComponents/shared/CardHeader";
  import ParticipantDetailsModal from "../pageComponents/participantsManagement/ParticipantDetailsModal";
  import PushNotificationModal from "../pageComponents/participantsManagement/PushNotificationModal";
  import RemoveParticipantsModal from "../pageComponents/participantsManagement/RemoveParticipantModal";
  import EditParticipantReferralModal from "../pageComponents/participantsManagement/EditParticipantReferralModal.vue";
  import EditParticipantModal from "../pageComponents/participantsManagement/EditParticipantModal";
  import ActionButtons from "../pageComponents/participantsManagement/ActionButtons";
  import Swal from "sweetalert2";
  import Plugin from "@revolist/revogrid-column-date";
  import role from "../utils/roleUtils";
  // import router from "@/router";
  export default {
    setup() {
      const progress = ref({});
      const showAddParticipant = ref(false);
      const showMessageUser = ref(false);
      const showDetails = ref(false);
      const showRemoveModal = ref(false);
      const showEditModal = ref(false);
      const showEditReferral = ref(false);
      const modalData = ref({});
      const showModal = ref("");
      const activeTab = ref('details')
      const isAdmin = ref(true);
      watch(
        () => showModal.value,
        (whichModal) => {
          switch (whichModal) {
            case "sendMessage":
              showMessageUser.value = true;
              break;
            case "details":
              showDetails.value = true;
              break;
            case "edit":
              showEditModal.value = true;
              break;
            case "editReferral":
              showEditReferral.value = true;
              break;
            case "progress":
              showProgressConfirmation(modalData.value.participant_id)
              break;
            case "remove":
              showRemoveModal.value = true;
              break;
            case "":
              closeSendModal();
          }
        }
      );
      let pagination = reactive({
        currentPage: 1,
        from: 0,
        perPage: 25,
      });
      const columnTypes = { date: new Plugin() };

      const columns = ref([
        {
          prop: "participant_id",
          name: "ID",
          size: 100,
          filter: "number",
          sortable: true,
          readonly: true,
        },
        {
          prop: "name",
          name: "Device",
          size: 150,
          filter: "string",
          sortable: true,
          readonly: true,
          cellProperties: (row) => {
            if (row.model.unique_device_id === "empty") {
              return {
                style: {
                  "background-color": "#FFE5E5",
                },
              };
            } else {
              return {
                style: {
                  "background-color": "#fff",
                },
              };
            }
          },
        },
        {
          prop: "first_name",
          name: "First name",
          size: 150,
          filter: "string",
          sortable: true,
          readonly: role.isBasic(),
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
        },
        {
          prop: "last_name",
          name: "Last name",
          size: 150,
          filter: "string",
          sortable: true,
          readonly: role.isBasic(),
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
        },
        {
          prop: "date_of_birth",
          columnType: "date",
          name: "DOB",
          size: 150,
          sortable: true,
          min: "1900-01-01",
          max: moment().format("YYYY-MM-DD"),
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {
                class: {
                  color: "red",
                },
              },
              moment(props.model[props.prop]).format("DD/MM/YYYY")
            );
          },
        },
        {
          prop: "email",
          name: "Email",
          size: 200,
          filter: "string",
          sortable: true,
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
        },
        {
          prop: "phone_number",
          name: "Phone number",
          size: 200,
          filter: "string",
          sortable: true,
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
        },
        {
          prop: "actions",
          name: "Actions",
          auto_size: true,
          size: 250,
          cellTemplate: VGridVueTemplate(ActionButtons),
          sortable: false,
          filter: false,
          readonly: true,
          cellProperties: (row) => {
            return rowTrafficLight(row);
          },
        },
      ]);
      const fetchData = () => {
        fetchParticipants(pagination.currentPage).then((response) => {
          if (location.hash.split("=")[1]) {
            const status = location.hash.split("=")[1];
            rows.value = response.data.filter((el) => {
              if (status === "onTrack") {
                return el.days_behind === 0;
              } else if (status === "slightlybehind") {
                return el.days_behind >= 1 && el.days_behind <= 2;
              } else {
                return el.days_behind > 2;
              }
            });
          } else {
            rows.value = response.data;
          }
          pagination = response.pagination;
        });
      };
      const rows = ref([]);

      const runUpdate = (ev) => {
        const participantId = ev.detail.model.participant_id;
        const newValue = ev.detail.val;
        const field = ev.detail.prop;
        let updateData = {};
        updateData[field] = newValue;
        if (!newValue) {
          Swal.fire("Error", "Value cannot be empty", "error");
          ev.detail.val = ev.detail.model[field];
        }
        updateParticipant(participantId, updateData);
      };
      const rowTrafficLight = (row) => {
        const daysBehind = parseInt(row.model.days_behind);
        if (daysBehind === 0) {
          return {
            style: {
              "background-color": "#e8f4ea",
            },
          };
        } else if (daysBehind > 0 && daysBehind < 3) {
          return {
            style: {
              "background-color": "#fefec8",
            },
          };
        } else if (daysBehind >= 3) {
          return {
            style: {
              "background-color": "#FFE5E5",
            },
          };
        }
      };
      const addParticipant = () => {
        router.push("/addparticipant");
      };
      const closeAddModal = () => {
        showAddParticipant.value = false;
      };
      const closeSendModal = () => {
        showMessageUser.value = false;
      };
      const closeDetails = () => {
        showDetails.value = false;
      };
      const closeRemoveModal = () => {
        showRemoveModal.value = false;
      };
      const closeEditModal = () => {
        showEditModal.value = false;
      };
      const closeEditReferralModal = () => {
        showEditReferral.value = false;
      };
      const setModalData = (partData) => {
        modalData.value = partData;
      };

      const showProgressConfirmation = (participantId) => {
        Swal.fire({
          title: 'Progress participant?',
          icon: 'question',
          text: 'Participant will be moved one day forward, and notified about it with a default message',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Yes!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: 'No, cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            progressParticipant(participantId)
              .then(() => {
                Swal.fire('Done', 'Participant notified', 'success')
              })
              .catch(() => {
                Swal.fire('Error', 'Unable to progress', 'error')
              })
          } else {
            modalData.value = {}
          }
        })
      }

      const refreshData = (tab) => {
        activeTab.value = tab
        fetchData()
      }
      onMounted(() => {
        if (role.isBasic()) {
          isAdmin.value = false;
          columns.value = columns.value.filter((el) => {
            return (
              // filters collumns NOT to display
              el.name !== "Email" &&
              el.name !== "Phone number" &&
              el.name !== "DOB" &&
              el.name !== "First name" &&
              el.name !== "Last name"
            );
          });
        }

        fetchData();
        listenAppEvent("modalData", (event) => {
          setModalData(event.detail.storage);
          const participantId = event.detail.storage.participant_id;
          if (participantId) {
            fetchData()
            loadProgress(participantId)
              .then((progr) => (progress.value = progr))
              .catch(() => (progress.value = null));
          } else {
            progress.value = null;
          }
        });
        listenAppEvent("showModal", (event) => {
          showModal.value = event.detail.storage;
          if (showModal.value === 'progress') {
            showProgressConfirmation(modalData.value.participant_id)
          }
        });
      });
      return {
        columns,
        rows,
        fetchData,
        runUpdate,
        addParticipant,
        showAddParticipant,
        closeAddModal,
        columnTypes,
        showMessageUser,
        modalData,
        showModal,
        closeSendModal,
        showDetails,
        closeDetails,
        showRemoveModal,
        progress,
        closeRemoveModal,
        closeEditModal,
        closeEditReferralModal,
        showEditModal,
        showEditReferral,
        setModalData,
        activeTab,
        isAdmin,
        refreshData
      };
    },
    /* eslint-disable */
    components: {
      PageHeader,
      CardHeader,
      VGrid,
      ActionButtons,
      EditParticipantReferralModal,
      ParticipantDetailsModal,
      PushNotificationModal,
      RemoveParticipantsModal,
      EditParticipantModal,
    },
  };
</script>
