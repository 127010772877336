import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import Dashboard from "../views/Dashboard.vue";
import AdminUser from "../views/AdminUserManagement.vue";
import ServerLogs from "../views/ServerLogs.vue";
import VideoSetManagement from "../views/VideoSetManagement.vue";
import VideoManagement from "../views/VideoManagement.vue";
import DevicesManagement from "../views/DevicesManagement.vue";
import ParticipantsManagement from "../views/ParticipantsManagement";
import Login from "../views/Login.vue";
import routerValidation from "../router/validatePath";
import addParticipantForm from "../views/addParticipantForm";

const routes = [
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    beforeEnter: routerValidation,
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/addparticipant",
        name: "addParticipants",
        components: { default: addParticipantForm },
      },
      {
        path: "/participants",
        name: "participants",
        components: { default: ParticipantsManagement },
      },
      {
        path: "/adminusers",
        name: "admin",
        components: { default: AdminUser },
      },
      {
        path: "/videoset",
        name: "videoset",
        components: { default: VideoSetManagement },
      },
      {
        path: "/authoriseddevices",
        name: "authoriseddevices",
        components: { default: DevicesManagement },
      },
      {
        path: "/video/:setId",
        name: "manageVideo",
        components: { default: VideoManagement },
      },
      {
        path: "/serverlogs",
        name: "serverlogs",
        components: { default: ServerLogs },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
