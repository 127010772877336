
exports.dispatchAppEvent = (eventName, payload) => {
  window.dispatchEvent(new CustomEvent(eventName, {
    detail: {
      storage: payload
    }
  }));
}
exports.listenAppEvent = (eventName, callback) => {
  window.addEventListener(eventName, (event) => {
    callback(event)
  });
}