<template>
  <div>
    <modal :show="showFlag">
      <form>
        <h6 class="heading-small text-muted mb-4">
          Delete video
          <strong>{{ video.title }}</strong>
        </h6>
        <div class="alert alert-danger" role="alert" v-if="error" type="danger">
          <strong>Warning! </strong>{{ error }}
        </div>
      </form>
      <div class="alert alert-warning">
        Are you sure you want to delete video
        <strong>{{ video.title }}</strong>
        <strong>{{ videoSetToDelete }} ?</strong>
      </div>
      <br />
      <div>
        <h3>
          This Video will be deleted immediately. You can't undo this action.
        </h3>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="deleteVideo" class="btn btn-danger my-4">Delete</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { removeVideo } from "../../api/videos";
export default {
  name: "delete-video-modal",
  emits: ["closeVideoDeleteModal", "refreshTable"],
  props: {
    video: { type: Object, default: () => {} },
    showFlag: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const error = ref(false);

    const closeModal = () => {
      emit("closeVideoDeleteModal", null);
    };
    const deleteVideo = () => {
      error.value = null;

      removeVideo(props.video.video_id)
        .then(() => {
          emit("closeVideoDeleteModal", null);
          emit("closeModal", props.video.video_id);
          emit("refreshTable");
        })
        .catch((err) => {
          error.value = err.response.data;
        });
    };

    return { deleteVideo, closeModal, error };
  },
};
</script>
