<template>
  <div>
    <modal :show="deviceEditFlag">
      <form>
        <h6 class="heading-small text-muted mb-4">Edit Device</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="deviceEditError"
          type="danger"
        >
          <strong>Warning! </strong>{{ deviceEditError }}
        </div>
        <div class="">
          <div class="">
            <div class="form-group">
              <label class="form-control-label">Identifiable Name</label>

              <input
                type="text"
                class="form-control"
                placeholder="Device Name"
                v-model="model.name"
                autocomplete="off"
              />

              <div class="form-group mt-3">
                <label class="form-control-label">
                  UUID - Universally Unique Device ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Device Name"
                  v-model="model.uuid"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="editMobileDevice" class="btn btn-primary my-4">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { updateDevice } from "../../api/devices";
export default {
  name: "eddit_mobile_device",
  emits: ["closeEditDeviceModal", "refreshTable"],
  props: {
    deviceEditFlag: { type: Boolean, default: false },
    deviceData: { type: Object, default: () => {} },
  },
  setup(props, { emit }) {
    const deviceEditError = ref(false);
    const model = ref({
      name: "",
      uuid: "",
    });

    watch(
      () => props.deviceData,
      (newVal) => {
        if (newVal) {
          model.value = props.deviceData;
        }
      }
    );

    const closeModal = () => {
      emit("closeEditDeviceModal", true);
    };
    const editMobileDevice = () => {
      deviceEditError.value = null;

      updateDevice(model.value.authorized_device_id, model.value)
        .then(() => {
          emit("closeEditDeviceModal", true);
          emit("refreshTable");
        })
        .catch((err) => {
          deviceEditError.value = err.response.data.message;
        });
    };

    return { editMobileDevice, closeModal, model, deviceEditError };
  },
};
</script>
