<template>
  <div>
    <modal :show="showFlag">
      <form>
        <h6 class="heading-small text-muted mb-4">
          Delete mobile device
          <strong>{{ name }}</strong>
        </h6>
        <div class="alert alert-danger" role="alert" v-if="error" type="danger">
          <strong>Warning! </strong>{{ error }}
        </div>
      </form>
      <div class="alert alert-warning">
        Are you sure you want to delete device
        <strong>{{ name }}</strong
        >&nbsp;?
      </div>
      <br />
      <div>
        <h3>
          This Device will be deleted immediately. Every participant which use
          it now will loose all access to any videos. You can't undo this action
        </h3>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="deleteAuthorisedDevice" class="btn btn-danger my-4">
          Delete
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { deleteDevice } from "../../api/devices";
export default {
  name: "delete-device-modal",
  emits: ["closeDeleteModal", "refreshTable"],
  props: {
    deviceId: { type: String, default: "" },
    name: { type: String, default: "" },
    showFlag: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const error = ref(false);

    const closeModal = () => {
      emit("closeDeleteModal", null);
    };
    const deleteAuthorisedDevice = () => {
      error.value = null;

      deleteDevice(props.deviceId)
        .then(() => {
          emit("closeDeleteModal", null);
          emit("closeModal", props.deviceId);
          emit("refreshTable");
        })
        .catch((err) => {
          error.value = err.response.data;
        });
    };

    return { deleteAuthorisedDevice, closeModal, error };
  },
};
</script>
