<template>
  <span>
    <div class="videos">
      <el-popover
        placement="top-start"
        v-if="hasThumbnail"
        :title="video.title"
        :width="200"
        trigger="hover"
        :content="video.description"
      >
        <template #reference>
          <img
            :src="video.video_thumbnail_url"
            @click="showModal"
            class="video-url"
            width="100"
            height="80"
          />
        </template>
      </el-popover>

      <span class="badge badge-proc badge-info badge-pill" v-else>
        {{ video.video_thumbnail_url }}
        <button
          @click="reloadThumb(video)"
          type="button"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-redo-alt"></i>
        </button>
      </span>
    </div>

    <video-player
      :video="video"
      @closeModal="closeModal"
      @refreshTable="refreshTable"
      :showFlag="showFlags[video.video_id]"
    />
  </span>
</template>
<script>
import { computed, ref } from "vue";
import VideoPlayer from "./videoPlayer";
import Swal from 'sweetalert2'
import { extractThumbnail } from "../../api/videos";
export default {
  props: {
    video: { type: Object, default: () => {} },
  },
  components: {
    VideoPlayer,
  },
  emits: ["refreshTable"],
  setup(props, { emit }) {
    const showFlags = ref({});
    const hasThumbnail = computed(() => {
      return (
        props.video.video_thumbnail_url &&
        props.video.video_thumbnail_url.startsWith("https")
      );
    });
    const showModal = () => {
      showFlags.value[props.video.video_id] = true;
    };
    const closeModal = () => {
      showFlags.value[props.video.video_id] = false;
    };
    const refreshTable = () => {
      emit("refreshTable");
    };
    function reloadThumb(video) {
      Swal.fire({
        title: "Would you like to extract thumbnail again?",
        text:
          "It will take a while to download this video and extract its thumbnail. You might want to reload this page in few minutes to see if the thumbnail extraction has completed.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, refresh it!",
      }).then((result) => {
        if (result.value) {
          extractThumbnail(video.video_id, video.file_location_path);
        } else {
          Swal.error('Error', 'Could not download video', 'error')
        }
      });
    };
    return {
      hasThumbnail,
      showModal,
      showFlags,
      reloadThumb,
      closeModal,
      refreshTable,
    };
  },
};
</script>
<style>
.badge-proc {
  margin: 10px;
}
.videos {
  display: inline-flex;
  filter: brightness(90%);
}
.videos:hover {
  transform: scale(1.1);
  margin-left: 5px;
  margin-right: 5px;
  transition: transform 0.5s ease;
  filter: brightness(110%);
}
</style>
