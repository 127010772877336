<template>
  <div>
    <modal :show="videoSetToDelete">
      <form>
        <h6 class="heading-small text-muted mb-4">Delete Video Set</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="error"
          type="danger"
        >
          <strong>Warning! </strong>{{ error }}
        </div>
      </form>
      <div class="alert alert-warning">
        Are you sure you want to delete Video Set id
        <strong>{{ videoSetToDelete }} ?</strong>
      </div>
      <br />
      <div>
        <h3>
          <span class="text-danger">&#9888;</span>
          This Video Set will be deleted immediately.
          <br />
          <br />
          <span class="text-danger">&#9888;</span>
          All users who are participating in this program will lose their
          progress.
          <br />
          <br />
          <span class="text-danger">&#9888;</span>Videos referenced from this
          set in to another set will be removed from cloud storage, and not
          accessible.
          <br />
          <br />
          <span class="text-danger">&#9888;</span>You can't undo this action.
        </h3>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="deleteSet" class="btn btn-danger my-4">Delete</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { deleteVideoSet } from "../../api/videoSetApi";
export default {
  name: "video-set-delete",
  props: {
    videoSetToDelete: { type: String, default: null },
  },
  setup(props, { emit }) {
    const error = ref(false);

    const closeModal = () => {
      emit("closeVideoSetModal", null);
    };
    const deleteSet = () => {
      error.value = null;

      deleteVideoSet(props.videoSetToDelete)
        .then(() => {
          emit("closeVideoSetModal", null);
          emit("refreshTable");
        })
        .catch((err) => {
          error.value = err.response.data;
        });
    };

    return { deleteSet, closeModal, error };
  },
};
</script>
