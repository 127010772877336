<template>
  <div>
    <modal :show="showFlag" modal-classes="modal-lg">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Edit Participant Referral</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="editModalError"
          type="danger"
        >
          <strong>Warning! </strong>{{ editModalError }}
        </div>
        <!-- Referral -->
        <div class="row form-row">
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Programme Referred To</label>
              <select
                class="form-control"
                v-model="participantData.programme_referred_to"
              >
                <option value="">---</option>
                <option value="pad-programme">PAD programme</option>
              </select>
              <div
                class="mt-0 mb-3"
                v-for="error of v$.programme_referred_to.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Referring Organisation</label>
              <input
                type="text"
                class="form-control"
                v-model="participantData.referring_organisation"
              />
              <div
                class="mt-0 mb-3"
                v-for="error of v$.referring_organisation.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Referring Healthcare Professional</label>
              <input
                type="text"
                class="form-control"
                v-model="participantData.referring_healthcare_professional"
              />
              <div
                class="mt-0 mb-3"
                v-for="error of v$.referring_healthcare_professional.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label>Referring Healthcare Professional Role</label>
              <input
                type="text"
                class="form-control"
                v-model="participantData.referring_healthcare_professional_role"
              />
              <div
                class="mt-0 mb-3"
                v-for="error of v$.referring_healthcare_professional_role
                  .$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-0 mt-3">
              <label class="form-check-label" for="flexCheckDefault">
                Contraindications to exercise checked?
              </label>
              <input
                v-model="participantData.checked_contraindications_to_exercise"
                class="input-checkbox"
                type="checkbox"
                id="flexCheckDefault"
              />
            </div>

            <div
              class="mt-0 mb-3"
              v-for="error of v$.checked_contraindications_to_exercise.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-group mb-0 mt-3">Comorbidities?</div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group mb-0 mt-3">
              <label class="form-check-label"> Smoker? </label>
              <select
                v-model="participantData.smoker"
                class="form-control comorbidities-section"
              >
                <option value="">---</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <div
              class="mt-0 mb-3"
              v-for="error of v$.smoker.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group mb-0 mt-3">
              <label class="form-check-label"> Diabetes? </label>
              <select
                v-model="participantData.diabetes"
                class="form-control comorbidities-section"
              >
                <option value="">---</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <div
              class="mt-0 mb-3"
              v-for="error of v$.diabetes.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group mb-0 mt-3">
              <label class="form-check-label"> Registered deaf? </label>
              <select
                v-model="participantData.registered_deaf"
                class="form-control comorbidities-section"
              >
                <option value="">---</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <div
              class="mt-0 mb-3"
              v-for="error of v$.registered_deaf.$errors"
              :key="error.$uid"
            >
              <small class="text-danger pt-2">{{ error.$message }}</small>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-group mb-0 mt-3">
              <div class="form-group mb-0">
                <label>Notes</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="participantData.notes"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button class="btn btn-primary my-4" @click="updateParticipantData">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
  import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";
  import { updateParticipant } from "../../api/participants";
  import useVuelidate from "@vuelidate/core";
  import editParticipanReferralModal from "../validationRules/editParticipanReferralModal";
  import { defineComponent, ref, reactive, watch } from "vue";

  export default defineComponent({
    name: "edit_participant",
    emits: ["refreshTable", "newParticipantData"],
    props: {
      showFlag: { type: Boolean, default: false },
      modalData: {
        type: Object,
        default: () => {},
        selected: { type: Object, default: () => {} },
      },
    },
    setup(props, { emit }) {
      watch(
        () => props.modalData,
        (newValue) => {
          if (newValue) {
            participantData.programme_referred_to =
              newValue.programme_referred_to;
            participantData.referring_organisation =
              newValue.referring_organisation;
            participantData.referring_healthcare_professional =
              newValue.referring_healthcare_professional;
            participantData.referring_healthcare_professional_role =
              newValue.referring_healthcare_professional_role;
            participantData.checked_contraindications_to_exercise =
              newValue.checked_contraindications_to_exercise;
            participantData.smoker = newValue.smoker;
            participantData.diabetes = newValue.diabetes;
            participantData.registered_deaf = props.modalData.registered_deaf;
          }
        }
      );

      let participantData = reactive({});
      const editModalError = ref(null);

      const rules = editParticipanReferralModal();
      const v$ = useVuelidate(rules, participantData);

      const closeModal = () => {
        emit("closeModal");
        v$.value.$reset();
        dispatchAppEvent("showModal", "");
      };
      const updateParticipantData = () => {
        v$.value.$validate().then((valid) => {
          if (valid) {
            updateParticipant(
              props.modalData.participant_id,
              participantData
            ).then(() => {
              window.location.reload(true);
            });
          }
        });
      };

      return {
        v$,
        editModalError,
        closeModal,
        updateParticipantData,
        participantData,
      };
    },
  });
</script>
<style>
  .middle {
    text-align: center;
  }
  .android {
    margin-right: 1em;
  }
</style>
