<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Total participants"
            type="gradient-orange"
            :sub-title="countedParticipants"
            icon="fas fa-user-md"
            class="mb-4 mb-xl-0 onHover"
            @click="goToParticipants()"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Yesterday viewed videos"
            type="gradient-red"
            :sub-title="yesterdayWatchedCount"
            icon="fas fa-eye"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>

        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Average videos per day"
            type="gradient-info"
            :sub-title="averagePerDay.toString()"
            icon="fas fa-question-circle"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
      <br />
      <div class="text-white">Participants:</div>
      <div class="row" v-if="dashboardData.participantsProgress">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            @click="goToParticipants('onTrack')"
            title="Participants on track"
            type="gradient-green"
            :sub-title="dashboardData.participantsProgress.onTrack"
            icon="fas fa-user"
            class="mb-4 mb-xl-0 onHover"
          >
          </stats-card>
        </div>

        <div class="col-xl-4 col-lg-6">
          <stats-card
            @click="goToParticipants('slightlybehind')"
            title="Participants slightly behind"
            type="gradient-orange"
            :sub-title="dashboardData.participantsProgress.slightlyBehind"
            icon="fas fa-user-plus"
            class="mb-4 mb-xl-0 onHover"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            @click="goToParticipants('behind')"
            title="Participants behind"
            type="gradient-red"
            :sub-title="dashboardData.participantsProgress.behind"
            icon="fas fa-user-times"
            class="mb-4 mb-xl-0 onHover"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <div class="card">
            <line-chart v-bind="lineChartProps" />
            <div class="spacer">.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { ref, computed, onMounted, watch } from "vue";
import { Chart, registerables } from "chart.js";
import { LineChart, useLineChart } from "vue-chart-3";
import { fetchDashboard } from "../api/dashboard";
import router from "@/router";
Chart.register(...registerables);

export default {
  components: { LineChart },
  setup() {
    onMounted(() => {
      fetchDashboard().then((data) => {
        if (data) {
          dashboardData.value = data;
          data.seenCount.forEach((i) => {
            chartValues.value[moment(i.created_at).format("LL")] =
              i.created_count;
          });
        }
      });
    });
    const dates = ref([]);
    const dashboardData = ref({});
    const chartValues = ref({});
    const chartX = ref({});
    const options = ref({
      type: "line",
      responsive: true,
      tooltips: {
        enabled: true,
        mode: "index",
        intersect: false,
      },

      animations: {
        radius: {
          duration: 600,
          easing: "linear",
          loop: (context) => context.active,
        },
      },
      hoverRadius: 4,
      hoverBackgroundColor: "#DFE1E3",
      interaction: {
        mode: "nearest",
        intersect: false,
        axis: "x",
      },

      plugins: {
        title: {
          display: true,
          text: "Watched videos in the last 7 days",
        },
      },
      xAxes: [
        {
          scales: {
            x: {
              type: "timeseries",
              title: {
                display: true,
              },
            },
          },
          display: true,
          type: "date",
          time: {
            parser: "MM/DD/YYYY",
            tooltipFormat: "ll",
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              day: "MM/DD/YYYY",
            },
          },
        },
      ],
    });

    const processDates = (startDate, endDate) => {
      dates.value.push(moment(startDate));
      const numOfDays =
        Math.abs(
          moment(startDate, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(endDate, "YYYY-MM-DD").startOf("day"), "days")
        ) + 1;

      for (let i = 1; i < numOfDays; i++) {
        let start = moment(dates.value[i - 1]).add(1, "days");
        dates.value.push(start);
      }
      const keys = Object.keys(chartValues.value);
      dates.value = dates.value.map((i) => {
        const formatted = moment(i).format("LL").toString();
        if (keys.includes(formatted)) {
          chartX.value[formatted] = chartValues.value[formatted];
        } else {
          chartX.value[formatted] = 0;
        }
        return formatted;
      });
    };
    watch(
      () => dashboardData.value,
      (newVal) => {
        if (newVal && dashboardData.value.seenCount.length > 0) {
          const len = newVal.seenCount.length;
          const endDate = moment(newVal.seenCount[len - 1].created_at).toDate();
          const startDate = moment(endDate).add(-7, "days").toDate();
          processDates(startDate, endDate);
        }
      }
    );
    const goToParticipants = (val) => {
      if (!val) router.push(`/participants`);
      else router.push(`/participants?state=${val}`);
    };
    const countedParticipants = computed(() => {
      if (!dashboardData.value.participantsCount) return 0;
      return dashboardData.value.participantsCount.counted.toString();
    });

    const countedSets = computed(() => {
      if (!dashboardData.value.videoSetCount) return 0;
      return dashboardData.value.videoSetCount.counted.toString();
    });

    const yesterdayWatchedCount = computed(() => {
      if (!dashboardData.value.yesterdayWatched) return 0;
      const [data] = dashboardData.value.yesterdayWatched.toString();
      return data ? data.created_count : "0";
    });

    const averagePerDay = computed(() => {
      if (!dashboardData.value.seenCount) return 0;
      const sum = dashboardData.value.seenCount
        .map((s) => parseInt(s.created_count))
        .reduce((a, b) => a + b, 0);
      const avg = sum / dashboardData.value.seenCount.length || 0;
      return avg;
    });

    const chartData = ref({
      labels: Object.keys(chartX.value),
      datasets: [
        {
          data: chartX.value,
          label: "Watched Videos",
          tension: 0.2,
          borderWidth: 2,
          borderColor: "#F4505E",
          fill: true,
          pointRadius: 2,
          backgroundColor: "rgba(255,0,0,0.4)",
        },
      ],
    });
    const { lineChartProps } = useLineChart({
      chartData: chartData.value,
      options: options.value,
    });

    return {
      dates,
      dashboardData,
      countedParticipants,
      countedSets,
      processDates,
      yesterdayWatchedCount,
      averagePerDay,
      chartData,
      options,
      lineChartProps,
      goToParticipants,
      chartValues,
      chartX,
    };
  },
};
</script>
<style>
.spacer {
  content: ".";
  visibility: hidden;
}
.onHover {
  transition: transform 0.1s;
}
.onHover:hover {
  transform: scale(1.05);
}
</style>
