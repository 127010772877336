<template>
  <div class="container-fluid">
    <div class="">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div>
            <form autocomplete="off">
              <h6 class="heading-small text-muted mb-4">Add New Participant</h6>
              <div
                class="alert alert-warning"
                role="alert"
                v-if="addModalError"
                type="danger"
              >
                <strong>Warning! </strong>{{ addModalError }}
              </div>
              <div class="row form-row">
                <div class="col-12 col-sm-12">
                  <div class="form-group mb-0">
                    <div class="form-group mb-0 mt-3">
                      <label>Participants' device</label>
                      <small class="help-block text-muted">
                        Showing all available (unassigned) devices
                      </small>
                      <vue-multiselect
                        v-model="participantData.unique_device_id"
                        :options="devices"
                        :close-on-select="true"
                        :clear-on-select="false"
                        placeholder="Select device"
                        label="name"
                        track-by="uuid"
                      />
                    </div>
                    <div
                      class="alert alert-warning mt-2"
                      v-if="devices.length === 0"
                    >
                      <i class="fas fa-info-circle"></i>
                      No devices available - please add one in the authorised
                      devices section or remove exissting device from user
                    </div>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.unique_device_id.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>First name:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="participantData.first_name"
                    />
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.first_name.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0">
                    <div class="form-group mb-0 mt-3">
                      <label>Last name:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="participantData.last_name"
                      />
                    </div>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.last_name.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Date of birth</label>
                    <datepicker
                      :class="'form-control'"
                      inputFormat="dd/MM/yyyy"
                      v-model="participantData.date_of_birth"
                    />
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.date_of_birth.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Gender</label>
                    <select
                      class="form-control"
                      v-model="participantData.gender"
                    >
                      <option>Please select...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Phone number Primary</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="participantData.phone_number"
                      />
                    </div>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.phone_number.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Phone number Secondary</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="participantData.phone_number_secondary"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Email address</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="participantData.email"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Language</label>
                      <select
                        class="form-control"
                        v-model="participantData.language"
                      >
                        <option
                          v-for="language in languages"
                          :key="language"
                          :value="language"
                        >
                          {{ language }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.language.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Address:</label>
                      <textarea
                        class="form-control"
                        style="resize: none"
                        rows="2"
                        v-model="participantData.address"
                      >
                      </textarea>
                    </div>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.address.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Programme Referred To</label>
                    <select
                      class="form-control"
                      v-model="participantData.programme_referred_to"
                    >
                      <option value="">---</option>
                      <option value="pad-programme">PAD programme</option>
                    </select>
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.programme_referred_to.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Referring Organisation</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="participantData.referring_organisation"
                    />
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.referring_organisation.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Referring Healthcare Professional</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        participantData.referring_healthcare_professional
                      "
                    />
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.referring_healthcare_professional
                        .$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label>Referring Healthcare Professional Role</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="
                        participantData.referring_healthcare_professional_role
                      "
                    />
                    <div
                      class="mt-0 mb-3"
                      v-for="error of v$.referring_healthcare_professional_role
                        .$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger pt-2">{{
                        error.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-0 mt-3">
                    <label class="form-check-label" for="flexCheckDefault">
                      Contraindications to exercise checked?
                    </label>
                    <input
                      v-model="
                        participantData.checked_contraindications_to_exercise
                      "
                      class="input-checkbox"
                      type="checkbox"
                      id="flexCheckDefault"
                    />
                  </div>

                  <div
                    class="mt-0 mb-3"
                    v-for="error of v$.checked_contraindications_to_exercise
                      .$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger pt-2">{{ error.$message }}</small>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="form-group mb-0 mt-3">Comorbidities?</div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group mb-0 mt-3">
                    <label class="form-check-label"> Smoker? </label>
                    <select
                      v-model="participantData.smoker"
                      class="form-control comorbidities-section"
                    >
                      <option value="">---</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div
                    class="mt-0 mb-3"
                    v-for="error of v$.smoker.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger pt-2">{{ error.$message }}</small>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group mb-0 mt-3">
                    <label class="form-check-label"> Diabetes? </label>
                    <select
                      v-model="participantData.diabetes"
                      class="form-control comorbidities-section"
                    >
                      <option value="">---</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div
                    class="mt-0 mb-3"
                    v-for="error of v$.diabetes.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger pt-2">{{ error.$message }}</small>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group mb-0 mt-3">
                    <label class="form-check-label"> Registered deaf? </label>
                    <select
                      v-model="participantData.registered_deaf"
                      class="form-control comorbidities-section"
                    >
                      <option value="">---</option>
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div
                    class="mt-0 mb-3"
                    v-for="error of v$.registered_deaf.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger pt-2">{{ error.$message }}</small>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="form-group mb-0 mt-3">
                    <div class="form-group mb-0">
                      <label>Notes</label>
                      <textarea
                        class="form-control"
                        rows="5"
                        v-model="participantData.notes"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <button class="btn btn-secondary my-4" @click="close">Close</button>
            <button class="btn btn-primary my-4" @click="saveParticipant">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueMultiselect from "vue-multiselect";
  import participantLanguage from "../pageComponents/shared/globalDirectives/participantLanguage";
  import Datepicker from "vue3-datepicker";
  import { availableDevices } from "../api/devices";
  import router from "@/router";
  import { addParticipant } from "../api/participants";
  import addParticipantValidationRules from "../pageComponents/validationRules/addParticipantValidationRules";
  import useVuelidate from "@vuelidate/core";
  import { defineComponent, ref, reactive, onMounted } from "vue";
  import moment from "moment";
  export default defineComponent({
    name: "add_participant",
    components: { VueMultiselect, Datepicker },
    emits: ["refreshTable"],
    props: {
      showFlag: { type: Boolean, default: false },
    },
    setup() {
      const { languages } = participantLanguage();
      const devices = ref([]);
      let participantData = reactive({
        first_name: "",
        last_name: "",
        address: "",
        email: "",
        phone_number: "",
        gender: "",
        unique_device_id: "",
        date_of_birth: new Date(),
        push_notification_id: "empty",
        phone_number_secondary: "",
        language: "",
        programme_referred_to: "",
        referring_organisation: "",
        referring_healthcare_professional: "",
        referring_healthcare_professional_role: "",
        notes: "",
        checked_contraindications_to_exercise: "",
        smoker: "",
        diabetes: "",
        registered_deaf: "",
      });
      const addModalError = ref(null);
      const clearParticipantData = () => {
        participantData.first_name = "";
        participantData.last_name = "";
        participantData.address = "";
        participantData.email = "";
        participantData.gender = "";
        participantData.date_of_birth = new Date();
        participantData.unique_device_id = "";
        participantData.phone_number = "";
        participantData.phone_number_secondary = "";
        participantData.language = "";
        participantData.programme_referred_to = "";
        participantData.referring_organisation = "";
        participantData.referring_healthcare_professional = "";
        participantData.referring_healthcare_professional_role = "";
        participantData.checked_contraindications_to_exercise = "";
        participantData.smoker = "";
        participantData.diabetes = "";
        participantData.registered_deaf = "";
      };

      const v$ = useVuelidate(addParticipantValidationRules(), participantData);

      const close = () => {
        clearParticipantData();
        router.push(`/participants`);
        v$.value.$reset();
      };
      const saveParticipant = () => {
        v$.value.$validate().then((valid) => {
          if (valid) {
            //hack to add value
            participantData.unique_device_id =
              participantData.unique_device_id.uuid;
            participantData.date_of_birth = moment(
              participantData.date_of_birth
            ).format("YYYY-MM-DD");
            addParticipant(participantData).then(() => {
              router.push(`/participants`);
            });
          }
        });
      };

      const loadDevices = () => {
        availableDevices().then((data) => {
          data.forEach((dev) => {
            devices.value.push({
              name: `${dev.name} - ${dev.uuid}`,
              uuid: dev.uuid,
              push_notification_id: dev.push_notification_id,
            });
          });
        });
      };

      onMounted(() => {
        loadDevices();
      });

      return {
        v$,
        participantData,
        addModalError,
        close,
        saveParticipant,
        devices,
        languages,
      };
    },
  });
</script>
<style>
  .middle {
    text-align: center;
  }
  .android {
    margin-right: 1em;
  }
  .input-checkbox {
    margin-left: 1em;
  }
  .comorbidities-section {
    max-width: 10rem;
  }
</style>
