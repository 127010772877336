import { required, helpers, minLength } from "@vuelidate/validators";

export default function () {
  return {
    name: {
      required: helpers.withMessage(
        "Identifiable name cannot be empty",
        required
      ),
      minLength: minLength(2),
    },
    uuid: {
      required: helpers.withMessage(
        "Universally unique identifier (UUID) cannot be empty",
        required
      ),
    },
  };
}
