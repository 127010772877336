<template>
  <div>
    <modal :show="displayAdminModal">
      <div v-if="!qr_code">
        <form autocomplete="off">
          <h6 class="heading-small text-muted mb-4">Add Admin User</h6>
          <div
            class="alert alert-warning"
            role="alert"
            v-if="loginError"
            type="danger"
          >
            <strong>Warning! </strong>{{ loginError }}
          </div>

          <div>
            <RoleSelect @setRole="setRole" selected="basic access" />
            <div class="form-group">
              <label class="form-control-label"
                >Username or Email address</label
              >
              <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                placeholder="Username"
                v-model="model.user_name"
              />

              <div
                class="mt-2 mb--3"
                v-for="error of v$.user_name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Password</label>
              <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                placeholder="Password"
                v-model="model.password"
              />
              <div
                class="mt-2 mb--3"
                v-for="error of v$.password.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">First Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="First Name"
              v-model="model.first_name"
            />
          </div>
          <div class="form-group">
            <label class="form-control-label">Last Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              v-model="model.last_name"
            />
          </div>
        </form>
      </div>
      <!-- qrcode -->
      <div v-else class="middle">
        <div class="middle">
          <div class="middle">
            <img src="img/brand/scan.png" width="80" height="80" />
          </div>
          <div>
            <small
              >1. Install and authenticator app on your mobile device.</small
            >
            <div>
              <a
                class="android"
                href="https://play.google.com/store/apps/details?id=com.authy.authy"
                target="_blank"
              >
                <img
                  src="img/brand/google-play.svg"
                  width="70"
                  height="40"
                  alt="Get it on Google Play"
                />
              </a>
              <a
                class=""
                href="https://itunes.apple.com/us/app/authy/id494168017"
                target="_blank"
              >
                <img
                  src="img/brand/app-store.svg"
                  width="70"
                  height="40"
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
          <div class="col">
            <small
              >2. Scan the fallowing QR code in your authenticator app.</small
            >
          </div>
          <div class="col">
            <img :src="qr_code" alt="Scan qr" />
          </div>
          <div class="col">
            <small>3. Close after<strong> successfull </strong>scan.</small>
          </div>
        </div>
      </div>
      <!-- qrcode -->
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button v-if="!qr_code" @click="addUser" class="btn btn-primary my-4">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import RoleSelect from "./RoleSelect";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { addAdminUser } from "../../api/adminApi";
export default {
  name: "add_user",
  components: { RoleSelect },
  props: {
    displayAdminModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const loginError = ref(false);
    const model = ref({
      user_name: "",
      password: "",
      first_name: "",
      last_name: "",
      role: "basic access",
    });
    const qr_code = ref("");
    const clearModal = () => {
      model.value.user_name = "";
      model.value.password = "";
      model.value.first_name = "";
      model.value.last_name = "";
      model.value.role = "";
      qr_code.value = "";
    };
    const rules = {
      user_name: {
        required: helpers.withMessage("Username cannot be empty", required),
        minLength: minLength(6),
      },
      password: {
        required: helpers.withMessage("Password cannot be empty", required),
        minLength: minLength(8),
      },
    };
    const v$ = useVuelidate(rules, model.value);

    const closeModal = () => {
      emit("closeModal", true);
      clearModal();
    };
    const setRole = (role) => {
      model.value.role = role;
    };
    const addUser = () => {
      loginError.value = null;
      v$.value.$validate().then((valid) => {
        if (valid) {
          addAdminUser(model.value)
            .then((data) => {
              qr_code.value = data.qr_code; // emit("closeModal", true);
              emit("refreshTable");
            })
            .catch((err) => {
              loginError.value = err.response.data.message;
            });
        }
      });
    };

    return { v$, addUser, closeModal, model, loginError, qr_code, setRole };
  },
};
</script>
<style>
.middle {
  text-align: center;
}
.android {
  margin-right: 1em;
}
</style>
