<template>
  <div>
    <page-header />
    <div class="container-fluid mt--4 col">
      <div class="card">
        <card-header
          buttonText="Add new video set"
          title="Video sets management"
          :clickFn="addNew"
        />

        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="videoSets">
            <template v-slot:columns>
              <th>Set ID</th>
              <th>Name</th>
              <th>Number of Weeks</th>
              <th>Videos per week</th>
              <th>Added</th>
              <th>Actions</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.fitness_video_set_id }}
              </th>
              <td>
                {{ row.item.set_name }}
              </td>
              <td>{{ row.item.number_of_weeks }}</td>
              <td>{{ row.item.videos_in_week }}</td>
              <td>{{ row.item.created_at.slice(0, 10) }}</td>
              <td class="py-2">
                <button
                  @click="
                    $router.push({
                      path: `/video/${row.item.fitness_video_set_id}`,
                    })
                  "
                  type="button"
                  class="btn btn-sm btn-info"
                >
                  Manage Videos
                  <i class="fas fa-film"></i>
                </button>
                <button
                  @click="editUser(row.item)"
                  type="button"
                  class="btn btn-sm btn-success"
                >
                  <i class="fas fa-edit"></i>
                </button>

                <button
                  @click="deleteVset(row.item.fitness_video_set_id)"
                  type="button"
                  class="btn btn-sm btn-danger"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
    <add-video-set-modal
      @closeModal="displayVideoSetModal = false"
      :displayAddSetModal="displayVideoSetModal"
      @refreshTable="refreshTable"
    />
    <delete-video-set-modal
      @closeVideoSetModal="setToDelete = null"
      :videoSetToDelete="setToDelete"
      @refreshTable="refreshTable"
    />
    <edit-video-set-modal
      @closeEditVideoModal="videoSetEdit = null"
      :videoSetEdit="videoSetEdit"
      @refreshTable="refreshTable"
    />
  </div>
  <!-- Contnet -->
</template>
<script>
import * as videoSetApi from "../api/videoSetApi";
import { ref, onMounted } from "vue";
import PageHeader from "../pageComponents/shared/PageHeader";
import CardHeader from "../pageComponents/shared/CardHeader";
import AddVideoSetModal from "../pageComponents/videoSetPage/AddVideoSetModal";
import DeleteVideoSetModal from "../pageComponents/videoSetPage/DeleteVideoSetModal";
import EditVideoSetModal from "../pageComponents/videoSetPage/EditVideoSetModal";

export default {
  name: "set-profile",
  components: {
    PageHeader,
    CardHeader,
    AddVideoSetModal,
    DeleteVideoSetModal,
    EditVideoSetModal,
  },
  setup() {
    const videoSets = ref();
    const displayVideoSetModal = ref(false);
    const setToDelete = ref(null);
    const videoSetEdit = ref(null);
    const getSets = () => {
      videoSetApi.getVideoSets().then((data) => {
        videoSets.value = data;
      });
    };
    const refreshTable = () => {
      getSets();
    };
    onMounted(() => {
      getSets();
    });
    const deleteVset = (fitness_video_set_id) => {
      setToDelete.value = fitness_video_set_id;
    };
    const editUser = (videoSet) => {
      videoSetEdit.value = videoSet;
    };

    const addNew = () => {
      displayVideoSetModal.value = true;
    };

    return {
      videoSets,
      displayVideoSetModal,
      setToDelete,
      refreshTable,
      deleteVset,
      editUser,
      videoSetEdit,
      addNew,
    };
  },
};
</script>
<style></style>
