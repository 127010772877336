<template>
  <div>
    <page-header />
    <div class="container-fluid mt--4 col">
      <div class="card">
        <card-header
          buttonText="Add Mobile Device"
          title="Authorised mobile devices"
          :clickFn="addNewDevice"
        />

        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="devices">
            <template v-slot:columns>
              <th>Database ID &nbsp;/&nbsp;Assigned to</th>
              <th>Identifiable Name</th>
              <th>Device Unique ID</th>
              <th>Actions</th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.authorized_device_id }}&nbsp;/&nbsp;
                <span
                  class="badge badge-pill badge-info"
                  style="font-size: 10px"
                  v-if="row.item.unique_device_id"
                >
                  <i class="fas fa-user-md text-info"></i>&nbsp;
                  {{ row.item.first_name }}&nbsp;{{ row.item.last_name }}
                </span>
                <span
                  v-else
                  class="badge badge-pill badge-warning"
                  style="font-size: 10px"
                >
                  Unassigned
                </span>
              </th>
              <td>
                {{ row.item.name || "not defined" }}
              </td>
              <td>{{ row.item.uuid }}</td>
              <td class="py-2">
                <button
                  @click="editMobileDevice(row.item)"
                  type="button"
                  class="btn btn-sm btn-success"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  @click="deleteMobileDevice(row.item)"
                  type="button"
                  v-if="!row.item.unique_device_id"
                  class="btn btn-sm btn-danger"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
    <add-mobile-device
      @refreshTable="loadDevices"
      @closeModal="showAddDevice = false"
      :displayAddDevice="showAddDevice"
    />

    <EditMobileDevice
      @refreshTable="loadDevices"
      :deviceData="deviceData"
      :deviceEditFlag="showEditDevice"
      @closeEditDeviceModal="showEditDevice = false"
    />

    <remove-mobile-device
      @refreshTable="loadDevices"
      :deviceId="deviceData.authorized_device_id"
      :showFlag="showDeleteDevice"
      @closeDeleteModal="showDeleteDevice = false"
    />
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getDevices, loadDevice } from "../api/devices";
import PageHeader from "../pageComponents/shared/PageHeader";
import CardHeader from "../pageComponents/shared/CardHeader";
import AddMobileDevice from "../pageComponents/devicesManagement/AddMobileDevice";
import EditMobileDevice from "../pageComponents/devicesManagement/EditMobileDevice";
import RemoveMobileDevice from "../pageComponents/devicesManagement/RemoveMobileDevice.vue";
export default {
  name: "devices-management",
  components: {
    CardHeader,
    PageHeader,
    AddMobileDevice,
    EditMobileDevice,
    RemoveMobileDevice,
  },
  setup() {
    const devices = ref([]);
    const showAddDevice = ref(false);
    const showEditDevice = ref(false);
    const showDeleteDevice = ref(false);
    const deviceData = ref({});

    const loadDevices = () => {
      getDevices().then((data) => {
        devices.value = data;
      });
    };

    onMounted(() => {
      loadDevices();
    });

    const editMobileDevice = (dev) => {
      if (dev.uuid) {
        loadDevice(dev.uuid).then((data) => {
          showEditDevice.value = true;

          deviceData.value = data[0];
        });
      }
    };

    const deleteMobileDevice = (dev) => {
      loadDevice(dev.uuid).then((data) => {
        showDeleteDevice.value = true;
        deviceData.value = data[0];
      });
    };

    const addNewDevice = () => {
      showAddDevice.value = true;
    };
    return {
      devices,
      editMobileDevice,
      deleteMobileDevice,
      showAddDevice,
      loadDevices,
      showEditDevice,
      deviceData,
      showDeleteDevice,
      addNewDevice,
    };
  },
};
</script>
<style></style>
