<template>
  <div class="buttons">
    <a @click="showDetails" class="btn btn-sm btn-info">
      <i class="fas fa-eye" @click="showDetails"></i>
    </a>
    <a
      v-show="!isBasic"
      class="btn btn-sm btn-warning"
      @click="editParticipant"
    >
      <i class="fas fa-edit" @click="editParticipant"></i>
    </a>

    <a
      v-show="!isBasic"
      class="btn btn-sm btn-warning"
      @click="editParticipantReferral"
    >
      <i class="fas fa-file-alt" @click="editParticipantReferral"></i>
    </a>
    <a
      @click="progressParticipant"
      v-if="model.unique_device_id && model.unique_device_id !== 'empty'"
      class="btn btn-sm btn-secondary"
    >
      <i class="fa fa-forward" @click="progressParticipant"></i>
    </a>
    <a
      @click="messageParticipant"
      v-if="model.unique_device_id && model.unique_device_id !== 'empty'"
      class="btn btn-sm btn-success btn-success"
    >
      <i class="fas fa-comment-dots" @click="messageParticipant"></i>
    </a>
    <a
      v-show="!isBasic"
      class="btn btn-sm btn-danger par-delete"
      @click="deleteParticipant"
    >
      <i class="far fa-trash-alt" @click="deleteParticipant"></i>
    </a>
  </div>
</template>
<script>
  import { dispatchAppEvent } from "../../../server/lib/push_notifications/appEvents";
  import { onMounted, ref } from "vue";
  import role from "../../utils/roleUtils";
  export default {
    ///WARNING!!!!! WORKAROUND
    /// SEE: https://github.com/revolist/revogrid/issues/230
    // NORMAL VUE EVENTS AND EMIT WON"T WORK FROM THIS COMPONENT (REVO GRID CELL COMPONENT ISSUE)
    props: ["rowIndex", "prop", "model", "data", "column"],
    setup(props) {
      const isBasic = ref(true);
      onMounted(() => {
        isBasic.value = role.isBasic();
      });
      const messageParticipant = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "sendMessage");
      };
      const showDetails = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "details");
      };
      const editParticipant = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "edit");
      };

      const editParticipantReferral = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "editReferral");
      };
      const deleteParticipant = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "remove");
      };
      const progressParticipant = () => {
        dispatchAppEvent("modalData", props.model);
        dispatchAppEvent("showModal", "progress");
      }
      return {
        messageParticipant,
        showDetails,
        deleteParticipant,
        editParticipant,
        editParticipantReferral,
        progressParticipant,
        isBasic,
      };
    },
  };
</script>
