<template>
  <div>
    <dashboard :uppy="uppy" :props="dash" />
  </div>
</template>

<script>
import { Dashboard } from "@uppy/vue";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { defineComponent, ref, computed, onMounted } from "vue";
export default defineComponent({
  components: {
    Dashboard,
  },
  emits: ["hasVideoUrl", "hasFileSize"],
  setup(props, { emit }) {
    const dash = ref({
      inline: true,
      trigger: "#pick-files",
      metaFields: [
        { id: "title", name: "Title", placeholder: "Title of the pic..." },
      ],
      thumbnailWidth: 150,
      thumbnailHight: 150,
      height: 300,
      width: "100%",
      // hideUploadButton: true,
    });
    const uppy = computed(() => {
      return new Uppy({});
    });
    const resetData = () => {
      uppy.value.reset();
    };
    onMounted(() => {
      uppy.value.on("file-added", (file) => {
        if (file.type.includes("video")) {
          emit("hasFileSize", file.size);
        }
      });
      uppy.value.use(AwsS3, {
        companionUrl: `${process.env.VUE_APP_API_BASE_URL}/companion`,
      });

      uppy.value.on("upload-success", (file, response) => {
        const httpBody = response.body;
        emit("hasVideoUrl", httpBody.location);
      });
    });
    return { uppy, dash, resetData };
  },
});
</script>
