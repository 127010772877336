<template>
  <div>
    <modal :show="userToDelete">
      <form>
        <h6 class="heading-small text-muted mb-4">Delete Admin User</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="uError"
          type="danger"
        >
          <strong>Warning! </strong>{{ uError }}
        </div>
      </form>
      <div class="alert alert-warning">
        Are you sure you want to delete user
        <strong>{{ userToDelete }} ?</strong>
      </div>
      <br />
      <div>
        <h3>
          This user will be deleted immediately. You can't undo this action.
        </h3>
      </div>
      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button @click="deleteUser" class="btn btn-danger my-4">
          Delete User
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref } from "vue";
import { deleteAdminUser } from "../../api/adminApi";
export default {
  name: "user-profile",
  props: {
    userToDelete: { type: String, default: null },
  },
  setup(props, { emit }) {
    const uError = ref(false);

    const closeModal = () => {
      emit("closeDeleteadminModal", null);
    };
    const deleteUser = () => {
      uError.value = null;

      deleteAdminUser(props.userToDelete)
        .then(() => {
          emit("closeDeleteadminModal", null);
          emit("refreshTable");
        })
        .catch((err) => {
          uError.value = err.response.data.message;
        });
    };

    return { deleteUser, closeModal };
  },
};
</script>
