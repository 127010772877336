import { axiosInstance } from "./axiosInstance";
export const addVideo = async (videoData) => {
  const { data } = await axiosInstance.post(`/api/video`, videoData);
  return data;
};

export const editVideo = async (videoId, videoData) => {
  const { data } = await axiosInstance.patch(
    `/api/video/${videoId}`,
    videoData
  );
  return data;
};

export const extractThumbnail = async (videoId, videoUrl) => {
  const extractionUrl = `/thumbs?videoUrl=${videoUrl}&videoId=${videoId}`;
  const { data } = await axiosInstance.get(extractionUrl);
  return data;
};

export const getVideoThumbnailsForSet = async (videoSetId) => {
  const videoUrl = `/api/video/thumbs/${videoSetId}`;
  const { data } = await axiosInstance.get(videoUrl);
  return data;
};

export const removeVideo = async (videoId) => {
  const videoUrl = `/api/video/${videoId}`;
  const { data } = await axiosInstance.delete(videoUrl);
  return data;
};

export const moveForward = async (progressId, week, day) => {
  const videoUrl = `/api/video/progress/forward/${progressId}`;
  const { data } = await axiosInstance.post(videoUrl, {
    day: day,
    week: week,
  });
  return data;
};
export const moveBackward = async (progressId, week, day) => {
  const videoUrl = `/api/video/progress/backward/${progressId}`;
  const { data } = await axiosInstance.post(videoUrl, {
    day: day,
    week: week,
  });
  return data;
};
