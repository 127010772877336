<template>
  <div class="card-header border-0">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="mb-0">{{ title }}</h3>
      </div>
      <div class="col text-right">
        <button
          v-if="fullAccess"
          @click="clickFn"
          class="btn btn-sm btn-primary"
          size="sm"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    buttonText: { type: String, default: "" },
    fullAccess: { type: Boolean, default: true },
    clickFn: { type: Function, default: () => {} },
  },
};
</script>
