// if u have an error in this file install "vetur" or newest es
import { ref } from "vue";

export default function () {
  const languages = ref([
    "English",
    "Polish",
    "Urdu",
    "Bengali",
    "Gujarati",
    "Arabic",
    "French",
    "Mandarin",
    "Cantonese",
    "Portuguese",
    "Spanish",
    "Tamil",
    "Turkish",
    "Italian",
    "Somali",
    "Lithuanian",
    "German",
    "Persian",
    "Filipino",
    "Romanian",
    "Other",
  ]);
  return { languages };
}
