<template>
  <div class="advanced-settings">
    <button class="btn btn-warning mt-5" @click="purgeCache">
      <i class="fas fa-broom"></i>&nbsp; Purge download cache
    </button>
    <button class="btn btn-danger mt-5" @click="progressForward">
      <i class="fas fa-stream"></i>&nbsp; Move particpant to a day/week
    </button>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { updateParticipant } from "../../../api/participants";
import { moveForward, moveBackward } from "../../../api/videos";
export default {
  props: {
    participant: { type: Object, default: () => {} },
    videoSet: { type: Object, default: () => {} },
    progress: { type: Object, default: () => {} },
  },
  setup(props) {
    const purgeCache = () => {
      Swal.fire({
        title: "Purge participants' download cache?",
        text:
          "All downloaded vdieos will be re-downloaded when the mobile app will be open",
        confirmButtonText: "Yes!",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          updateParticipant(props.participant.participant_id, {
            purge_cache: true,
          }).then(() => {
            Swal.fire(
              "Done",
              "Download cache has been purged. All videos will be re-downloaded upon-first launch",
              "success"
            );
          });
        }
      });
    };
    const progressForward = async () => {
      const { value: formValues } = await Swal.fire({
        title: "Enter week and day number",
        showConfirmButton: true,
        confirmButtonText: "Change",
        showCancelButton: true,
        html: `<span class='badge badge-info'>Week: ${props.progress.current_week}, Day: ${props.progress.current_day}</span><br/><small>Saving will progress particpant to a given week and day</small>
          <br/><label>Week</label><input id="week" class="swal2-input" type="number" value="${props.progress.current_week}" min="${props.progress.current_week}" max="${props.videoSet.number_of_weeks}"><br/>
          <label>Day</label><input id="day" class="swal2-input"  value="${props.progress.current_day}" min="1" type="number" max="${props.videoSet.videos_in_week}">`,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById("week").value,
            document.getElementById("day").value,
          ];
        },
      });
      const [week, day] = formValues;
      if (
        week == props.progress.current_week &&
        day < props.progress.current_day 
      ) {
        moveBackward(props.progress.fitness_participation_progress_id, week, day)
          .then(() => {
            window.location.reload(true);
          })
          .catch((e) => {
            Swal.fire("Error", e.message, "error");
          });
        
      } else if (week >= props.progress.current_week) {
        moveForward(props.progress.fitness_participation_progress_id, week, day)
          .then(() => {
            window.location.reload(true);
          })
          .catch((e) => {
            Swal.fire("Error", e.message, "error");
          }); 
      } else {
        Swal.fire(
          "Error",
          "Cannot move participant back to other week. Only values greater or lesser than current day, and greater than current week are allowed",
          "error"
        );
      }
    };
    return {
      purgeCache,
      progressForward,
    };
  },
};
</script>
