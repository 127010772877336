<template>
  <div class="form-group" v-if="isFullAccess">
    <label class="form-control-label">User's access level</label>
    <select class="form-control" @change="setRole">
      <option value="basic access" :selected="selected == 'basic access'">
        Basic access
      </option>
      <option value="full access" :selected="selected == 'full access'">
        Full access
      </option>
    </select>
    <small class="help-text">Full access allows to view identfiable data</small>
  </div>
</template>
<script>
import { computed } from "vue";
import roleUtils from "../../utils/roleUtils";
export default {
  props: {
    selected: { type: String, default: "basic access" },
  },
  emits: ["setRole"],
  setup(props, { emit }) {
    function setRole(ev) {
      emit("setRole", ev.target.value);
    }

    const isFullAccess = computed(() => {
      return roleUtils.isFull();
    });
    return {
      setRole,
      isFullAccess,
    };
  },
};
</script>
