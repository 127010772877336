<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <div>
            <div class="form-group">
              <base-alert v-if="loginError" type="danger">
                <strong>Unauthorized! </strong>Please check the email address,
                password or MFA and try again.
              </base-alert>
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-control-label"
                  >Email Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  v-model="model.user_name"
                />
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="form-control-label"
                  >Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  v-model="model.password"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassCode1" class="form-control-label"
                  >One Time Passcode:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassCode1"
                  placeholder="One Time Passcode"
                  v-model="model.mfa_token"
                />
              </div>
              <div class="text-center">
                <button
                  type="primary"
                  @click="logIn"
                  class="btn btn-primary my-4"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import router from "@/router";
import * as api from "../api/login";
import BaseAlert from "../components/BaseAlert.vue";

export default {
  components: { BaseAlert },
  name: "login",
  setup() {
    const loginError = ref(false);
    const model = ref({
      user_name: "",
      password: "",
      mfa_token: "",
    });
    const logIn = () => {
      loginError.value = false;
      const signInData = {
        user_name: model.value.user_name.trim(),
        password: model.value.password.trim(),
        mfa_token: model.value.mfa_token.trim(),
      };
      api
        .signIn(signInData)
        .then(() => {
          router.push("/dashboard");
        })
        .catch(() => {
          loginError.value = true;
        });
    };
    return {
      model,
      loginError,
      logIn,
    };
  },
};
</script>
<style></style>
