<template>
  <div>
    <modal :show="modalData.day_number" modal-classes="modal-lg">
      <form autocomplete="off">
        <h6 class="heading-small text-muted mb-4">Add Video</h6>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="addVideoError"
          type="danger"
        >
          <strong>Warning! </strong>{{ addVideoError }}
        </div>
        <div class="row form-row">
          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label>Video Title:</label>
              <input
                type="text"
                v-model="videoData.title"
                class="form-control"
                placeholder="Title..."
              />
              <div
                class="mt-2 mb--3"
                v-for="error of v$.title.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12">
            <div class="form-group">
              <label class="hello">Description:</label>
              <textarea
                type="text"
                v-model="videoData.description"
                class="form-control"
                placeholder="Description..."
              />
              <div
                class="mt-2 mb--3"
                v-for="error of v$.description.$errors"
                :key="error.$uid"
              >
                <small class="text-danger pt-2">{{ error.$message }}</small>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label>Video:</label>
            </div>

            <div v-if="videoData.video_thumbnail_url !== 'processing....'">
              <img
                :src="videoData.video_thumbnail_url"
                class="video-url"
                width="100"
                height="80"
              />

              <div>
                <button
                  @click="replaceVideo = !replaceVideo"
                  type="button"
                  class="btn btn-sm btn-warning ml--1 mt-1"
                >
                  Replace Video?
                </button>
              </div>
            </div>
            <br />
            <div :class="setStyle">
              Add Video:

              <VideoUploader
                @hasVideoUrl="setVideoUrl"
                @hasFileSize="setFileSize"
                ref="uploader"
              />
              <br />
            </div>
          </div>
        </div>
      </form>

      <template v-slot:footer>
        <button class="btn btn-secondary my-4" @click="closeModal">
          Close
        </button>
        <button class="btn btn-primary my-4" @click="saveVideo">
          Save changes
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
/* eslint-disable */

  import { editVideo, extractThumbnail } from "../../api/videos";
  import useVideoValidationRules from "../validationRules/useVideoValdiationRules"
  import VideoUploader from "../shared/VideoUploader";
  import useVuelidate from "@vuelidate/core";
  import { defineComponent, ref, reactive, watch, computed } from "vue";
  export default defineComponent({
    name: "add_video_modal",
    components: {
      VideoUploader,
    },
    props: {
      modalData: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
      const uploader = ref(VideoUploader);
      const replaceVideo = ref(false);
      let videoData = reactive({
        title: "",
        description: "",
        video_thumbnail_url: "",
      });
      const setStyle = computed(() => {
        return replaceVideo.value ? null : "disable-uplad";
      });
      watch(
        () => props.modalData,
        (newVal) => {
          if (newVal) {
            videoData.title = props.modalData.title;
            videoData.description = props.modalData.description;
            videoData.video_thumbnail_url = props.modalData.video_thumbnail_url;
          }
        }
      );
      const addVideoError = ref(null);
      const image = ref();
      const setVideoUrl = (url) => {
        videoData.file_location_path = url;
        videoData.video_thumbnail_url = "processing....";
      };
      const setFileSize = (size) => {
        videoData.file_size = size;
      };

      const v$ = useVuelidate(useVideoValidationRules(), videoData);

      const closeModal = () => {
        emit("closeModal");
        uploader.value.resetData();
        v$.value.$reset();
      };
      const saveVideo = () => {
        v$.value.$validate().then((valid) => {
          if (valid) {
            editVideo(props.modalData.video_id, videoData).then((data) => {
              extractThumbnail(data.video_id, data.file_location_path);
              closeModal();
            });
          }
        });
      };

      return {
        v$,
        image,
        videoData,
        replaceVideo,
        addVideoError,
        setVideoUrl,
        closeModal,
        saveVideo,
        setFileSize,
        setStyle,
        uploader,
      };
    },
  });
</script>
<style>
  .middle {
    text-align: center;
  }
  .android {
    margin-right: 1em;
  }
  .disable-uplad {
    filter: blur(2px);
    pointer-events: none;
  }
</style>
