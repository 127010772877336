import { axiosInstance } from "./axiosInstance";

export const getLogList = async () => {
  const { data } = await axiosInstance.get(`/api/logs/getloglist`);
  return data;
};

export const downloadLogFile = async (logName) => {
  const { data } = await axiosInstance.get(`/api/logs/download/${logName}`);
  return data;
};
