import { required, helpers, minLength } from "@vuelidate/validators";
// used for edit participant
export default function () {
  return {
    first_name: {
      required: helpers.withMessage("First name cannot be empty", required),
      minLength: minLength(2),
    },
    last_name: {
      required: helpers.withMessage("Last name cannot be empty", required),
      minLength: minLength(2),
    },
    unique_device_id: {
      required: helpers.withMessage("Device cannot be empty", required),
      minLength: minLength(2),
    },
    address: {
      required: helpers.withMessage("Address cannot be empty", required),
      minLength: minLength(2),
    },

    date_of_birth: {
      required: helpers.withMessage("DOB cannot be empty", required),
    },
    phone_number: {
      required: helpers.withMessage("Phone number cannot be empty", required),
      minLength: minLength(2),
    },
  };
}
