<template>
  <div class="mt-2 mb--3" v-for="error of errors" :key="error.$uid">
    <small class="text-danger pt-2">{{ error.$message }}</small>
  </div>
</template>
<script>
export default {
  props: ["errors"],
};
</script>
