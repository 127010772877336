<template>
  <div class="flex-row-auto">
    <div class="card card-custom card-stretch">
      <div class="py-3">
        <h3>
          {{ participant.first_name }}&nbsp;{{ participant.last_name }}
          <i
            class="fas fa-2x fa-venus text-muted"
            v-if="participant.gender === 'female'"
          ></i>
          <i
            class="fas fa-2x fa-mars text-muted"
            v-if="participant.gender === 'male'"
          ></i>
        </h3>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> System ID </span>
          <span class="text-muted text-info">
            {{ participant.participant_id }}
            &nbsp;<i class="fas fa-id-card text-info"></i
          ></span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Device </span>
          <span class="text-muted text-success">
            {{ participant.name }} with id:
            <pre style="display: inline">{{
              participant.unique_device_id
            }}</pre>
            &nbsp;<i class="fas fa-tablet text-success"></i
          ></span>
        </div>
        <!-- visible for admins only -->
        <div v-if="isBasicRole">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Date of birth </span>
            <span class="text-muted text-warning">
              {{ dateOfBirth }}
              &nbsp;<i class="fas fa-birthday-cake text-warning"></i
            ></span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Email </span>
            <a :href="`mailto:${participant.email}`">
              {{ participant.email }}&nbsp;
              <i class="fas fa-at"></i>
            </a>
          </div>

          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Language </span>
            <span class="text-muted text-info">
              {{ participant.language }}
              &nbsp;<i class="fas fa-language text-info"></i
            ></span>
          </div>

          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Primary Phone Number </span>
            <span class="text-muted">
              <a :href="`tel://${participant.phone_number}`">
                {{ participant.phone_number }}
                <i class="fas fa-phone"></i>
              </a>
            </span>
          </div>

          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Secondary Phone Number </span>
            <span class="text-muted">
              <a :href="`tel://${participant.phone_number_secondary}`">
                {{ participant.phone_number_secondary }}
                <i class="fas fa-phone"></i>
              </a>
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2"> Address </span>
            <span class="text-muted">
              {{ participant.address }}
              <i class="fas fa-building text-orange"></i>
            </span>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-2"
            v-if="participant.last_ip_address"
          >
            <span class="font-weight-bold mr-2"> Last known IP address: </span>
            <a :href="`vnc://${participant.last_ip_address}`">
              {{ participant.last_ip_address }}&nbsp;
              <i class="fas fa-share-square text-orange"></i>
            </a>
          </div>
        </div>
        <!-- visible for admins only -->
      </div>
    </div>

    <button
      v-if="isBasicRole"
      type="button"
      class="btn btn-info btn-sm btn-block mb-2"
      @click="displayReferral = !displayReferral"
    >
      Show Referral
    </button>
    <div v-if="displayReferral">
      <!-- <h3 class="mb-2">Referall:</h3> -->
      <div class="card card-custom card">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Programme Referred To </span>
          <span class="text-muted">
            {{ participant.programme_referred_to }}
          </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Referring Organisation </span>
          <span class="text-muted">
            {{ participant.referring_organisation }}
          </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">
            Referring Healthcare Professional
          </span>
          <span class="text-muted">
            {{ participant.referring_healthcare_professional }}
          </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">
            Referring Healthcare Professional Role
          </span>
          <span class="text-muted">
            {{ participant.referring_healthcare_professional_role }}
          </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">
            Contraindications to exercise checked?
          </span>
          <span
            v-if="participant.checked_contraindications_to_exercise"
            class="text-success"
          >
            Yes
          </span>
          <span v-else class="text-danger"> No </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Smoker? </span>
          <span v-if="participant.smoker" class="text-dark"> Yes </span>
          <span v-else class="text-dark"> No </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Diabetes? </span>
          <span v-if="participant.diabetes" class="text-dark"> Yes </span>
          <span v-else class="text-dark"> No </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2"> Registered Deaf? </span>
          <span v-if="participant.registered_deaf" class="text-dark">
            Yes
          </span>
          <span v-else class="text-dark"> No </span>
        </div>
      </div>

      <div class="card card-custom card-stretch">
        <div class="form-group mb-0 mt-3">
          <div class="form-group mb-0">
            <label class="text-dark">Notes</label>
            <textarea
              disabled
              class="form-control"
              rows="10"
              :value="participant.notes"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import { computed, ref } from "vue";
  import role from "../../../utils/roleUtils";
  export default {
    props: {
      participant: { type: Object, default: () => {} },
    },
    setup(props) {
      const displayReferral = ref(false);
      const dateOfBirth = computed(() => {
        if (props.participant.date_of_birth) {
          return moment(props.participant.date_of_birth).format("DD/MM/YYYY");
        } else {
          return "not specified";
        }
      });

      const isBasicRole = computed(() => {
        return role.isFull();
      });

      return {
        dateOfBirth,
        isBasicRole,
        displayReferral,
      };
    },
  };
</script>
