import { axiosInstance } from "./axiosInstance";

export const getVideoSets = async () => {
  const { data } = await axiosInstance.get(`/api/videoset`);
  return data;
};

export const getVideoSetById = async (setId) => {
  const { data } = await axiosInstance.get(`/api/videoset/${setId}`);
  return data;
};

export const addVideoSet = async (setData) => {
  const { data } = await axiosInstance.post(`/api/videoset`, setData);
  return data;
};

export const deleteVideoSet = async (video_set_id) => {
  const { data } = await axiosInstance.delete(`/api/videoset/${video_set_id}`);
  return data;
};

export const patchVideoSet = async (video_set_id, setData) => {
  const { data } = await axiosInstance.patch(
    `/api/videoset/${video_set_id}`,
    setData
  );
  return data;
};
