<template>
  <div>
    <page-header />
    <div v-if="videoSets" class="container-fluid mt--4 col">
      <div class="card" :key="componentKey">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Videos</h3>
            </div>
            <div class="text-right">
              <nav aria-label="Page navigation example">
                <ul class="pagination rjustify-content-cente">
                  <li v-for="index in videoSets.number_of_weeks" :key="index">
                    <button
                      :class="[
                        currentWeek === index ? `btn-success` : `btn-light`,
                        `btn`,
                        `btn-sm`,
                        `ml-1`,
                      ]"
                      @click="selectWeek(index)"
                    >
                      Week {{ index }}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead class="">
              <tr>
                <th scope="col">Day Number</th>
                <th scope="col">Video</th>
                <th scope="col">ADD</th>
              </tr>
            </thead>
            <draggable
              v-model="videosArray"
              tag="tbody"
              item-key="name"
              :sort="false"
              :drag="false"
            >
              <template #item="{ element }">
                <tr>
                  <td>{{ element.day_number }}</td>

                  <td>
                    <VideoItem
                      v-for="video in elementVideos(element)"
                      @refreshTable="refreshTable"
                      :key="video.video_id"
                      :video="video"
                    />
                  </td>

                  <td>
                    <button
                      type="button"
                      class="btn btn btn-success"
                      @click="
                        addVideoModal(
                          element.day_number,
                          element.week_number,
                          element.fitness_video_set_id
                        )
                      "
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>

      <rawDisplayer class="col-3" :value="videosArray" title="List" />
    </div>
    <AddVideoModal
      :modalData="addVideoModalData"
      @closeModal="closeVideoModal"
      @refreshTable="refreshTable"
    />
  </div>
  <!-- Contnet -->
</template>
<script>
import AddVideoModal from "../pageComponents/videoManagmentPage/AddVideoModal";
import VideoItem from "../pageComponents/videoManagmentPage/videoItem";
import PageHeader from "../pageComponents/shared/PageHeader";
import draggable from "vuedraggable";
import { getVideoSetById } from "../api/videoSetApi";
import { getVideoThumbnailsForSet } from "../api/videos";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
export default {
  name: "videoManagement", //avoid name of components that match html tags - it was "video" previously
  components: {
    PageHeader,
    draggable,
    AddVideoModal,
    VideoItem,
  },
  setup() {
    const addVideoModalData = ref(false);
    const currentWeek = ref(1);
    const groupedByWeek = ref({});
    const componentKey = ref(0);
    const videosArray = ref([
      {
        fitness_video_set_id: 1,
        thumbnail: "",
        week_number: 8,
        day_number: 1,
      },
    ]);
    const videoSets = ref();
    const route = useRoute();

    const addVideoModal = (day, week, set_id) => {
      addVideoModalData.value = {
        day_number: day,
        week_number: week,
        fitness_video_set_id: set_id,
        set_name: videoSets.value.set_name,
      };
    };
    const closeVideoModal = () => {
      addVideoModalData.value = false;
    };
    const getSet = (setId) => {
      getVideoSetById(setId).then((data) => {
        videoSets.value = data;
        generateTemplateList(
          videoSets.value.fitness_video_set_id,
          videoSets.value.videos_in_week,
          currentWeek.value
        );
        getVideoThumbnailsForSet(setId).then((videos) => {
          Object.keys(videos).forEach((weekNumber) => {
            groupedByWeek.value[weekNumber] = videos[weekNumber];
            componentKey.value += 1;
          });
          generateTemplateList(
            videoSets.value.fitness_video_set_id,
            videoSets.value.videos_in_week,
            currentWeek.value
          );
        });
      });
    };
    const selectWeek = (weekNumber) => {
      componentKey.value += 1;
      currentWeek.value = weekNumber;
      generateTemplateList(
        videoSets.value.fitness_video_set_id,
        videoSets.value.videos_in_week,
        currentWeek.value
      );
    };
    const generateTemplateList = (setId, maxDays, weekNumber) => {
      let newTepmlateList = [];
      for (let i = 0; i < maxDays; i++) {
        newTepmlateList.push({
          fitness_video_set_id: setId,
          thumbnail: "",
          week_number: weekNumber,
          day_number: i + 1,
          title: "",
          description: "",
          videos: groupedByWeek.value[weekNumber] || [],
        });
      }
      videosArray.value = newTepmlateList;
    };
    // const loadAndGroupThumbnails = (setID) => {
    //   getVideoThumbnailsForSet(setID).then((videos) => {
    //     Object.keys(videos).forEach((weekNumber) => {
    //       groupedByWeek.value[weekNumber] = videos[weekNumber];
    //     });
    //   });
    // };
    const refreshTable = () => {
      const setId = route.params.setId;
      getSet(setId);
      // loadAndGroupThumbnails(setId);
      // setTimeout(function () {
      //   getSet(setId);
      // }, 1000);
      componentKey.value += 1;
    };
    const elementVideos = (element) => {
      return element.videos.filter((v) => v.day_number == element.day_number);
    };
    onMounted(() => {
      refreshTable();
    });
    return {
      videoSets,
      videosArray,

      generateTemplateList,
      selectWeek,
      componentKey,
      currentWeek,
      addVideoModal,
      addVideoModalData,
      closeVideoModal,

      groupedByWeek,
      refreshTable,
      elementVideos,
    };
  },
};
</script>
<style>
.active-item {
  background-color: blue;
}
</style>
