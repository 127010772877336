<template>
  <div>
    <page-header />
    <div class="container-fluid mt--4 col">
      <div class="card">
        <card-header
          :clickFn="showModal"
          title="Admin users"
          buttonText="Add admin user"
        />
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="users">
            <template v-slot:columns>
              <th>Admin ID</th>
              <th>Username</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Access level</th>
              <th>Added</th>
              <th>Actions</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.admin_user_id }}
              </th>
              <td>
                {{ row.item.user_name }}
              </td>
              <td>{{ row.item.first_name }}</td>
              <td>{{ row.item.last_name }}</td>
              <td>
                <span
                  class="badge badge-pill"
                  :class="
                    row.item.role === 'full access'
                      ? 'badge-danger'
                      : 'badge-success'
                  "
                >
                  {{ row.item.role }}
                </span>
              </td>
              <td>{{ formattedDate(row.item) }}</td>
              <td class="py-2">
                <button
                  @click="editUser(row.item)"
                  type="button"
                  class="btn btn-sm btn-success"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  @click="deleteUser(row.item.user_name)"
                  type="button"
                  class="btn btn-sm btn-danger"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
    <post-admin-user-modal
      @closeModal="displayAddAdminModal = false"
      :displayAdminModal="displayAddAdminModal"
      @refreshTable="refreshTable"
    />
    <delete-admin-user-modal
      @closeDeleteadminModal="userToDelete = null"
      :userToDelete="userToDelete"
      @refreshTable="refreshTable"
    />
    <edit-admin-user-modal
      @closeEditAdminModal="userToEdit = null"
      :userToEdit="userToEdit"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import moment from "moment";
import * as adminApi from "../api/adminApi";
import { ref, onMounted } from "vue";
import PageHeader from "../pageComponents/shared/PageHeader";
import CardHeader from "../pageComponents/shared/CardHeader";
import PostAdminUserModal from "../pageComponents/adminUserPage/PostAdminUserModal";
import DeleteAdminUserModal from "../pageComponents/adminUserPage/DeleteAdminUserModal";
import EditAdminUserModal from "../pageComponents/adminUserPage/EditAdminUserModal";
export default {
  name: "user-profile",
  components: {
    CardHeader,
    PageHeader,
    PostAdminUserModal,
    DeleteAdminUserModal,
    EditAdminUserModal,
  },
  setup() {
    const users = ref();
    const displayAddAdminModal = ref(false);
    const userToDelete = ref(null);
    const userToEdit = ref(null);
    const getUsers = () => {
      adminApi.getUsers().then((data) => {
        users.value = data;
      });
    };
    const refreshTable = () => {
      getUsers();
    };
    onMounted(() => {
      getUsers();
    });
    const deleteUser = (user_name) => {
      userToDelete.value = user_name;
    };
    const editUser = (user) => {
      userToEdit.value = user;
    };
    const formattedDate = (date) => {
      return moment(date.created_at).format("DD/MM/YYYY");
    };
    const showModal = () => {
      displayAddAdminModal.value = true;
    };
    return {
      users,
      displayAddAdminModal,
      userToDelete,
      refreshTable,
      deleteUser,
      editUser,
      userToEdit,
      formattedDate,
      showModal,
    };
  },
};
</script>
<style></style>
