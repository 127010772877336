import roleUtils from "../utils/roleUtils";
const routerValidation = (to, from, next) => {
  const token = roleUtils.getJwtCookie();
  if (token) {
    next();
  } else {
    next("/login");
  }
};

export default routerValidation;
