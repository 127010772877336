import { axiosInstance } from "./axiosInstance";

export const getUsers = async () => {
  const { data } = await axiosInstance.get(`/api/admin`);
  return data;
};

export const addAdminUser = async (userData) => {
  const { data } = await axiosInstance.post(`/api/admin`, userData);
  return data;
};

export const deleteAdminUser = async (user_name) => {
  const { data } = await axiosInstance.delete(`/api/admin/${user_name}`);
  return data;
};

export const patchUser = async (user_name, body) => {
  const { data } = await axiosInstance.patch(`/api/admin/${user_name}`, body);
  return data;
};
