<template>
  <div>
    <page-header />
    <div class="container-fluid mt--4 col">
      <div class="card">
        <card-header title="Server logs" />
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="logList">
            <template v-slot:columns>
              <th>Log Created Date</th>
              <th>Actions</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <td>{{ row.item }}</td>
              <td class="py-2">
                <button
                  @click="viewLogFile(row.item)"
                  type="button"
                  class="btn btn-sm btn-info"
                >
                  View Log
                  <i class="fas fa-view"></i>
                </button>
                <button
                  @click="downloadLogFile(row.item)"
                  type="button"
                  class="btn btn-sm btn-warning"
                >
                  Download Log
                  <i class="fas fa-download"></i>
                </button>
              </td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
  </div>
  <!-- Contnet -->
</template>
<script>
import { ref, onMounted } from "vue";
import * as logApi from "../api/serverLogsApi";
import PageHeader from "../pageComponents/shared/PageHeader";
import CardHeader from "../pageComponents/shared/CardHeader";

export default {
  name: "set-profile",
  components: { PageHeader, CardHeader },
  setup() {
    const logList = ref(null);
    onMounted(() => {
      logApi.getLogList().then((data) => (logList.value = data));
    });
    const downloadLogFile = (logname) => {
      logApi.downloadLogFile(logname).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", logname);
        document.body.appendChild(link);
        link.click();
      });
    };

    const viewLogFile = (logname) => {
      logApi.downloadLogFile(logname).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        window.open(url, logname, "width=600,height=500,scrollbars=yes");
      });
    };
    return { logList, downloadLogFile, viewLogFile };
  },
};
</script>
<style></style>
